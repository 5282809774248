// UserGroupsContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';

const UserGroupsContext = createContext([]);

export function useUserGroups() {
  return useContext(UserGroupsContext);
}

export function UserGroupsProvider({ children }) {
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    async function fetchUserGroups() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const groups = user.getSignInUserSession().getIdToken().payload['cognito:groups'];
        setUserGroups(groups);
      } catch (error) {
        // Handle error
        console.error('Error fetching user groups:', error);
      }
    }

    fetchUserGroups();

    // Listen for authentication events to update userGroups
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        fetchUserGroups();
      }
    });
  }, []);

  return (
    <UserGroupsContext.Provider value={userGroups}>
      {children}
    </UserGroupsContext.Provider>
  );
}
