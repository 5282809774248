import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { ColumnProps } from 'antd/es/table';
import { Avatar, Table, Button, Modal, Tag } from 'antd';

import { IPatient } from '../../../interfaces/patient';

import axios from 'axios';
import '../../../custom-table.css'; // Import your custom CSS file
import SensorForm from '../../../layout/components/sensors/SensorForm';
import { useUserGroups } from '../../../UserGroupsContext';

type Props = {
    patients: IPatient[];
    onEditPatient?: (patient: IPatient) => void;
    onDeletePatient?: (id: string) => void;
};

const SensorsTable = ({
    patients,
    onEditPatient = () => null,
    onDeletePatient = () => null
}: Props) => {
    const history = useHistory();

    const [patient, setPatient] = useState(null);
    const [visit, setVisit] = useState(null);
    const [urinalysisTable, setUrinalysisTable] = useState([]);
    const [visibility, setVisibility] = useState(false);
    const [visitVisibility, setVisitVisibility] = useState(false);
    const [flag, setFlag] = useState(false);
    const [usedSensors, setUsedSensors] = useState([])
    const [lastVisit, setlastVisit] = useState({})
    const [disabled, setDisabled] = useState(true)
    const closeModal = () => setVisibility(false);
    const closeVisitModal = () => setVisitVisibility(false);
    const handleShowInfo = () => history.push('/vertical/patient-profile');
    const handleDeletePatient = (id) => onDeletePatient(id);
    const [missingSensorsModalVisible, setMissingSensorsModalVisible] = useState(false);
    const userGroups = useUserGroups();

    const actions = (patient: IPatient) => (
        <div className='buttons-list nowrap'>
            {/* <Button shape='circle' onClick={handleShowInfo}>
        <span className='icofont icofont-external-link' />
      </Button> */}
            <Button onClick={() => handleEditSensor(patient)} shape='circle' type='primary'>
                <span className='icofont icofont-edit-alt' />
            </Button>
            {/* <Button onClick={handleDeletePatient.bind({}, patient.id)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button> */}
        </div>
    );

    const columns = [
        {
            key: 'id',
            dataIndex: 'Study_ID',
            title: 'Study ID',
            sorter: (a, b) => (parseInt(a.Study_ID.S) > parseInt(b.Study_ID.S) ? 1 : -1),
            render: (Study_ID, record, rowIndex) => {
                let color = 'black'  //rowIndex == 0 ? 'black' : '#a5a5a5'
                return (
                    <span className='nowrap' style={{ color: color }}>
                        {Study_ID.S}
                    </span>
                );
            }
        },
        {
            title: 'Visit Date',
            dataIndex: 'Visit_Date',
            key: 'visitDate',
            sorter: (a, b) => (parseInt(a.Visit_Date.S) > parseInt(b.Visit_Date.S) ? 1 : -1),
            render: (text) => { if (text != undefined) return text.S }
        },
        { title: 'pH', dataIndex: 'pH', key: 'pH', render: (text) => { if (text != undefined) return text.S } },
        { title: 'Glucose', dataIndex: 'Glucose', key: 'glucose', render: (text) => { if (text != undefined) return text.S } },
        { title: 'Protein', dataIndex: 'Protein', key: 'protein', render: (text) => { if (text != undefined) return text.S } },
        { title: 'RBC', dataIndex: 'RBC', key: 'RBC', render: (text) => { if (text != undefined) return text.S } },
        {
            key: 'actions',
            title: 'Actions',
            render: actions,
        }
    ];

    if (!disabled) {
        columns.push({
            key: 'actions',
            title: 'Actions',
            render: actions,
        } as any);
    }

    const handleEditSensor = (patient) => {

        setPatient(patient);
        setVisibility(true);
    };

    const refreshData = () => {
        setFlag(!flag); // Toggle the flag to trigger a refresh
    };

    const showMissingSensorsModal = (formattedIds, len) => {
        Modal.error({
            title: 'Missing Sensors',
            content: (
                <div>
                    <p>Missing sensors ({len}):</p>
                    <pre>{formattedIds}</pre>
                </div>
            ),
            onOk() {
                setMissingSensorsModalVisible(false);
            },
        });
    };

    useEffect(() => {
        let start = async () => {
            try {
                let urinalysis = (await axios.post("http://54.174.123.25:8000/visits/", { f: "GetVisit", tableName: "urinalysis" })).data
                setUrinalysisTable(urinalysis)
            } catch (error) {
                console.error("Error fetching patient data:", error);
            }
        };
        start()
    }, [flag, lastVisit, visit])

    const pagination = patients.length <= 10 ? false : {};

    return (
        <>
            <Table
                pagination={pagination}
                className='accent-header'
                rowKey='id'
                dataSource={urinalysisTable}
                columns={columns}
                rowClassName={"gray-row"} //(record, index) => index % 2 === 0 ? 'gray-row' : 'gray-row'
            />

            {patient && (
                <Modal
                    open={visibility}
                    footer={null}
                    onCancel={closeModal}
                    title={<h3 className='title'>Edit Sensor</h3>}
                >
                    <SensorForm
                        submitText='Update sensor'
                        onCancel={closeModal}
                        onSubmit={onEditPatient}
                        patient={patient}
                        onDataUpdated={refreshData}
                        isEdit={true}
                    />
                </Modal>
            )}
        </>
    );
};

export default SensorsTable;




