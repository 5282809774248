import React, { useState, useEffect } from 'react';
import 'antd/dist/reset.css';

import { Switch, Route, Redirect } from 'react-router-dom';

import VerticalLayout from './layout/vertical/Vertical';
import HorizontalLayout from './layout/horizontal/Horizontal';

import NotFound from './pages/sessions/404';
import { defaultRoutes, sessionRoutes } from './routing';

import './App.less';
import './App.scss';
import { useHideLoader } from './hooks/useHideLoader';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import '@aws-amplify/ui-react/styles.css';
import { withAuthenticator, Heading } from '@aws-amplify/ui-react';
import { Button, Modal } from 'antd';
import SignIn from './pages/sessions/Sign-in';
import { UserGroupsProvider, useUserGroups } from './UserGroupsContext';
Amplify.configure(awsconfig);


const Routes = ({ routes, layout = '' }) => (
  <Switch>
    {routes.map((route, index) => (
      <Route
        key={index}
        exact={route.exact}
        path={layout.length > 0 ? `/${layout}/${route.path}` : `/${route.path}`}
        component={() => <route.component />}
      />
    ))}

    <Route path='*'>
      <Redirect to='/public/page-404' />
    </Route>
  </Switch>
);

const DefaultRoutes = ({ layout }) => <Routes routes={defaultRoutes} layout={layout} />;

const SessionRoutes = () => <Routes routes={sessionRoutes} layout='public' />;

const App = () => {
  useHideLoader();
  const userGroups = useUserGroups()
  const [visible, setVisible] = useState(false)
  const [text, setText] = useState(""); // Initialize text state

  const handleAuthentication = (authenticated) => {
    setVisible(authenticated); // Set the visibility based on authentication status
  };

  useEffect(() => {
    // Listen for authentication events using Hub
    const handleAuthChange = (data) => {
      const { payload } = data;
      if (payload.event === "signIn" || payload.event === "signOut") {
        fetchUserGroups();
      }
    };

    // Add the event listener
    Hub.listen("auth", handleAuthChange);

    // Fetch user groups when the component mounts
    fetchUserGroups();

    // Clean up the event listener when the component unmounts
    return () => {
      Hub.remove("auth", handleAuthChange);
    };
  }, []);

  // Create a function to fetch user groups and update text
  const fetchUserGroups = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups = user.getSignInUserSession().getIdToken().payload['cognito:groups'];
      // Set the text based on user groups
      if (groups.includes("CareGiver-Belinson-BladderCancer")) {
        setText("BELINSON (BLADDER CANCER)");
      } else if (groups.includes("CareGiver-Belinson-BladderCancer-Review")) {
        setText("BELINSON BLADDER CANCER (Review)");
      } else if (groups.includes("CareGiver-Belinson-BladderCancer-Edit")) {
        setText("BELINSON BLADDER CANCER (Edit)");
      }
      else if (groups.includes("RnD")) {
        setText("RND");
      } else if (groups.includes("Mfg")) {
        setText("MFG");
      } else if (groups.includes("Admin")) {
        setText("ADMIN");
      } else {
        // Set a default text if user doesn't belong to any specific group
        setText("");
      }
    } catch (error) {
      // Handle error
      console.error('Error fetching user groups:', error);
    }
  };

  return (
    <>
      {/* <Authenticator></Authenticator>  */}
      <UserGroupsProvider>
        {/* <div style={styles.container as React.CSSProperties}> */}
        {!visible && (<SignIn onAuthenticated={handleAuthentication} />)}

        {visible && (<Switch>

          <Route path='/' exact>
            <Redirect to='/vertical/default-dashboard' />
          </Route>

          <Route path='/public'>
            <SessionRoutes />
          </Route>

          <Route path='/horizontal'>
            <HorizontalLayout>
              <DefaultRoutes layout='horizontal' />
            </HorizontalLayout>
          </Route>

          <Route path='/vertical'>
            <VerticalLayout>
              <DefaultRoutes layout='vertical' />
            </VerticalLayout>
          </Route>

          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>)}
        {visible && (<div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 9999 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h6>{text}</h6>
            <Button type='primary' onClick={async () => { setVisible(!visible); }} style={{ marginLeft: '10px' }}>
              <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '5px' }} />
            </Button>
          </div>
        </div>)}
      </UserGroupsProvider>
    </>
  );
};


const styles = {
  container: { width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', },
  todo: { marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default App;





// import React, { createContext, useContext, useEffect, useState } from 'react';
// import 'antd/dist/reset.css';

// import { Switch, Route, Redirect } from 'react-router-dom';

// import VerticalLayout from './layout/vertical/Vertical';
// import HorizontalLayout from './layout/horizontal/Horizontal';

// import NotFound from './pages/sessions/404';
// import { defaultRoutes, sessionRoutes } from './routing';

// import './App.less';
// import './App.scss';
// import { useHideLoader } from './hooks/useHideLoader';
// import { Authenticator } from '@aws-amplify/ui-react';
// import { Amplify, Auth } from 'aws-amplify';
// import awsconfig from './aws-exports';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
// import '@aws-amplify/ui-react/styles.css';
// import { withAuthenticator, Heading } from '@aws-amplify/ui-react';
// import { Button, Modal } from 'antd';
// import SignIn from './pages/sessions/Sign-in';
// import { UserGroupsProvider, useUserGroups } from './UserGroupsContext';
// Amplify.configure(awsconfig);

// const UserGroupsContext = createContext([]);

// const Routes = ({ routes, layout = '' }) => (
//   <Switch>
//     {routes.map((route, index) => (
//       <Route
//         key={index}
//         exact={route.exact}
//         path={layout.length > 0 ? `/${layout}/${route.path}` : `/${route.path}`}
//         component={() => <route.component />}
//       />
//     ))}

//     <Route path='*'>
//       <Redirect to='/public/page-404' />
//     </Route>
//   </Switch>
// );

// const DefaultRoutes = ({ layout }) => <Routes routes={defaultRoutes} layout={layout} />;

// const SessionRoutes = () => <Routes routes={sessionRoutes} layout='public' />;

// const App = () => {
//   useHideLoader();
//   const [visible, setVisible] = useState(false)
//   const [userGroups, setUserGroups] = useState([]);

//   useEffect(() => {
//     async function fetchUserGroups() {
//       try {
//         const user = await Auth.currentAuthenticatedUser();
//         const groups = user.getSignInUserSession().getIdToken().payload['cognito:groups'];
//         setUserGroups(groups);
//       } catch (error) {
//         // Handle error
//         console.error('Error fetching user groups:', error);
//       }
//     }

//     fetchUserGroups();
//   }, []);

//   const handleAuthentication = (authenticated) => {
//     setVisible(authenticated); // Set the visibility based on authentication status
//   };


//   let text = ""
//   console.log(userGroups)
//   userGroups.forEach(group => {
//     if (group == "CareGiver-Belinson-BladderCancer") {
//       text = "BELINSON (BLADDER CANCER)"
//     }
//     if (group == "Admin") {
//       text = "ADMIN"
//     }
//   })

//   return (
//     <>
//       {/* <div style={styles.container as React.CSSProperties}> */}
//       {!visible && (<SignIn onAuthenticated={handleAuthentication} />)}

//       {visible && (<Switch>

//         <Route path='/' exact>
//           <Redirect to='/vertical/default-dashboard' />
//         </Route>

//         <Route path='/public'>
//           <SessionRoutes />
//         </Route>

//         <Route path='/horizontal'>
//           <HorizontalLayout>
//             <DefaultRoutes layout='horizontal' />
//           </HorizontalLayout>
//         </Route>

//         <Route path='/vertical'>
//           <VerticalLayout>
//             <DefaultRoutes layout='vertical' />
//           </VerticalLayout>
//         </Route>

//         <Route path='*'>
//           <NotFound />
//         </Route>
//       </Switch>)}
//       {visible && (<div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 9999 }}>
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <h6>{text}</h6>
//           <Button type='primary' onClick={() => { setVisible(!visible) }} style={{ marginLeft: '10px' }}>
//             <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '5px' }} />
//           </Button>
//         </div>

//       </div>)}
//     </>
//   );
// };


// const styles = {
//   container: { width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', },
//   todo: { marginBottom: 15 },
//   input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
//   todoName: { fontSize: 20, fontWeight: 'bold' },
//   todoDescription: { marginBottom: 0 },
//   button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
// }

// export default App;