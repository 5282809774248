import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { ColumnProps } from 'antd/es/table';
import { Avatar, Table, Button, Modal, Tag } from 'antd';

import { IPatient } from '../../../interfaces/patient';

import axios from 'axios';
import { Console, log } from 'console';
import EditObservationVisit from '../../../layout/components/patients/EditObservtionVisit';
import EditUrinaryVisit from '../../../layout/components/patients/EditUrinaryVisit';
import EditPathologyVisit from '../../../layout/components/patients/EditPathologyVisit';
import { check } from 'prettier';
import links from '../../../Config/links.json'
import reservedWords from '../../../Config/ReservedWords.json'
import { Get } from '../../../crud/sensors'
import { GetVisit } from '../../../crud/visits'
import { Auth } from 'aws-amplify';
import '../../../custom-table.css'; // Import your custom CSS file
import SensorForm from '../../../layout/components/sensors/SensorForm';
import { message } from 'antd';
import { useUserGroups } from '../../../UserGroupsContext';

type Props = {
    patients: IPatient[];
    onEditPatient?: (patient: IPatient) => void;
    onDeletePatient?: (id: string) => void;
};

const SensorsTable = ({
    patients,
    onEditPatient = () => null,
    onDeletePatient = () => null
}: Props) => {
    const history = useHistory();

    const [patient, setPatient] = useState(null);
    const [visit, setVisit] = useState(null);
    const [sensorsTable, setSensorsTable] = useState([]);
    const [visibility, setVisibility] = useState(false);
    const [visitVisibility, setVisitVisibility] = useState(false);
    const [flag, setFlag] = useState(false);
    const [usedSensors, setUsedSensors] = useState([])
    const [notAddSensors, setNotAddSensors] = useState([])
    const [observations, setObservations] = useState([])
    const [pathology, setPathology] = useState([])
    const [urinalysis, setUrinalysis] = useState([])
    const [expandedRows, setExpandedRows] = useState({});
    const [lastPatientID, setlastPatientID] = useState()
    const [editType, setType] = useState()
    const [lastVisit, setlastVisit] = useState({})
    const [disabled, setDisabled] = useState(true)
    const closeModal = () => setVisibility(false);
    const closeVisitModal = () => setVisitVisibility(false);
    const handleShowInfo = () => history.push('/vertical/patient-profile');
    const handleDeletePatient = (id) => onDeletePatient(id);
    const [missingSensorsModalVisible, setMissingSensorsModalVisible] = useState(false);
    const userGroups = useUserGroups();

    const actions = (patient: IPatient) => (
        <div className='buttons-list nowrap'>
            {/* <Button shape='circle' onClick={handleShowInfo}>
        <span className='icofont icofont-external-link' />
      </Button> */}
            <Button onClick={() => handleEditSensor(patient)} shape='circle' type='primary'>
                <span className='icofont icofont-edit-alt' />
            </Button>
            {/* <Button onClick={handleDeletePatient.bind({}, patient.id)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button> */}
        </div>
    );

    const usedCheck = (id) => {
        let icon = usedSensors.includes(id) ? "icofont-ui-check" : "icofont-ui-close"
        return (
            <span className='d-flex align-baseline nowrap' style={{ color: "black" }}>
                <span className={icon} style={{ fontSize: 16 }} />
            </span>
        );
    }

    const columns = [
        {
            key: 'id',
            dataIndex: 'Sensor_ID',
            title: 'Sensor ID',
            sorter: (a, b) => (parseInt(a.Sensor_ID.S) > parseInt(b.Sensor_ID.S) ? 1 : -1),
            render: (Sensor_ID, record, rowIndex) => {
                let color = 'black'  //rowIndex == 0 ? 'black' : '#a5a5a5'
                return (
                    <span className='nowrap' style={{ color: color }}>
                        {Sensor_ID.S}
                    </span>
                );
            }
        },
        {
            key: 'qa',
            dataIndex: 'QA',
            title: 'QA',
            render: (QA, record, rowIndex) => {
                let color = 'black'
                let text = QA ? QA.S : ""
                return (
                    <span className='nowrap' style={{ color: color }}>
                        {text}
                    </span>
                )
            }
        },
        {
            key: 'used',
            dataIndex: 'Sensor_ID',
            title: 'Used',
            render: (Sensor_ID, record, rowIndex) => {
                return (
                    usedCheck(Sensor_ID.S)
                )
            },
        },
        {
            key: 'notes',
            dataIndex: 'Notes',
            title: 'Notes',
            render: (Notes, record, rowIndex) => {
                let text = Notes ? Notes.S : ""
                return (
                    <span className='nowrap' style={{ color: "black" }}>
                        {text}
                    </span>
                )
            },
        },
        {
            key: 'actions',
            title: 'Actions',
            render: actions,
        }
    ];

    // if (disabled) {
    //     columns.pop()
    // }

    const handleEditSensor = (patient) => {
        console.log(patient);
        setPatient(patient);
        setVisibility(true);
    };

    const refreshData = () => {
        setFlag(!flag); // Toggle the flag to trigger a refresh
    };

    const showMissingSensorsModal = (formattedIds, len) => {
        Modal.error({
            title: 'Missing Sensors',
            content: (
                <div>
                    <p>Missing sensors ({len}):</p>
                    <pre>{formattedIds}</pre>
                </div>
            ),
            onOk() {
                setMissingSensorsModalVisible(false);
            },
        });
    };

    useEffect(() => {
        let start = async () => {
            try {
                //get all the patients
                let sensors = await Get()
                sensors.sort((a, b) => (parseInt(a.Sensor_ID.S) > parseInt(b.Sensor_ID.S) ? -1 : 1))
                setSensorsTable(sensors)
                let observations = await GetVisit("observations") // too heavy for axios need to find solution
                let usedSensors = [], notUsedSensors = [], flag = false
                for (let i = 0; i < observations.length; i++) {
                    for (let j = 0; j < sensors.length; j++) {
                        if (observations[i].LOT_Number && observations[i].LOT_Number.S == sensors[j].Sensor_ID.S) {
                            usedSensors.push(sensors[j].Sensor_ID.S)
                            flag = true
                            break;
                        }
                    }
                    if (!flag && observations[i].Run_Number && observations[i].Run_Number.S == "1") notUsedSensors.push(observations[i].LOT_Number.S)
                    flag = false
                }

                setUsedSensors(usedSensors)
                notUsedSensors.sort((a, b) => parseInt(a) - parseInt(b))
                // let permission = (await axios.post("http://54.174.123.25:8000/patients/", { f: "CheckPermission", userGroups: userGroups, permissions: ["RnD", "admin"] })).data
                //user roll is not RnD or admin
                // if (!permission) {
                //     setDisabled(false)
                // }
                setNotAddSensors(notUsedSensors)

            } catch (error) {
                console.error("Error fetching patient data:", error);
            }
        };
        start()
    }, [flag, lastVisit, visit])


    useEffect(() => {
        if (notAddSensors.length > 0) {
            const formattedIds = notAddSensors.map((id, index) => `${index + 1}. ${id}`).join('\n');
            showMissingSensorsModal(formattedIds, notAddSensors.length);
        }
    }, [notAddSensors.length])
    const pagination = patients.length <= 10 ? false : {};

    return (
        <>
            <Table
                pagination={pagination}
                className='accent-header'
                rowKey='id'
                dataSource={sensorsTable}
                columns={columns}
                rowClassName={"gray-row"} //(record, index) => index % 2 === 0 ? 'gray-row' : 'gray-row'
            />

            {patient && (
                <Modal
                    open={visibility}
                    footer={null}
                    onCancel={closeModal}
                    title={<h3 className='title'>Edit Sensor</h3>}
                >
                    <SensorForm
                        submitText='Update sensor'
                        onCancel={closeModal}
                        onSubmit={onEditPatient}
                        patient={patient}
                        onDataUpdated={refreshData}
                        isEdit={true}
                    />
                </Modal>
            )}
        </>
    );
};

export default SensorsTable;




