// SignIn.js

import React from 'react';
import { Button, Form, Input, Switch } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';
import PublicLayout from '../../layout/public/Public';
import { useForm } from 'antd/es/form/Form';
import { useNavigateHome } from '../../utils/use-navigate-home';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../../../src/aws-exports';
import { useHistory } from 'react-router-dom'; // Import useHistory
import { message } from 'antd';
import "../../layout/public/Public.scss"
import logo from "../../logo.jpg"
import { useUserGroups } from '../../UserGroupsContext';
import { Authenticator } from '@aws-amplify/ui-react';

// Configure Amplify with your AWS Amplify configuration
Amplify.configure(awsconfig);
const { Item } = Form;

const SignIn = ({ onAuthenticated }) => { // Add onAuthenticated prop
  const [form] = useForm();
  const navigateHome = useNavigateHome();
  const history = useHistory(); // Initialize useHistory
  const userGroups = useUserGroups();

  const login = async () => {
    try {
      // Set AWS_SDK_LOAD_CONFIG before any AWS SDK operations
      process.env.AWS_SDK_LOAD_CONFIG = '1';

      await Auth.signOut();

      form.validateFields().then(async (data) => {
        try {
          await Auth.signIn(data.login, data.password);
          const user = await Auth.currentAuthenticatedUser();
          const groups = user.getSignInUserSession().getIdToken().payload['cognito:groups'];
          message.success('Successfully logged in');
          onAuthenticated(true);
        } catch (error) {
          message.error('username or password incorrect');
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <PublicLayout bgImg={`/content/login_image.jpg`}>
      <div className='content-header'>
        <img src={logo} alt="Logo" className='logo' />
      </div>

      <p className='text-color-200'>Login to access your Account</p>

      <Form form={form} layout='vertical' className='mb-4'>
        <Item name='login' rules={[{ required: true, message: <></> }]}>
          <Input placeholder='Login' />
        </Item>
        <Item name='password' rules={[{ required: true, message: <></> }]}>
          <Input placeholder='Password' type='password' />
        </Item>

        {/* <div className='d-flex align-items-center mb-4'>
          <Switch defaultChecked /> <span className='ml-2'>Remember me</span>
        </div> */}

        <Button
          block={false}
          type='primary'
          onClick={login}
          htmlType='submit'
          icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
        >
          Login
        </Button>
        {/* <Authenticator>
          {({ signOut, user }) => (
            <main>
              <h1>Hello {user.username}</h1>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator> */}
      </Form>
      {/* <br />
      <p className='mb-1'>
        <a href='#'>Forgot password?</a>
      </p> */}

    </PublicLayout>
  );
};

export default SignIn;
