import React, { useState, useEffect } from 'react';

import { Card } from 'antd';

import ReactEcharts from 'echarts-for-react';

import hospitalOptions from './charts/hospital-options';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import FontAwesome icons

import {
  patientsGenderOptions,
  collectionMethodOptions,
  patientsAgeOptions,
  pathologyOptions,
  labelGroupOptions,
  labelMultiClassOptions
} from './charts/patients-options';

import { useFetchPageData, usePageData } from '../../../hooks/usePage';

import { IAppointment } from '../../../interfaces/patient';
import { IPageData } from '../../../interfaces/page';
import axios from "axios"
import { getByQuery, getFullItemsUrinalysis, getFullItemsPathology, getSpecificUrineID } from '../../../crud/visits';
import { useUserGroups } from '../../../UserGroupsContext';

const pageData: IPageData = {
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboards',
      route: 'default-dashboard'
    },
    {
      title: 'Default'
    }
  ]
};


const DashboardPage = () => {
  const [appointments] = useFetchPageData<IAppointment[]>('./data/last-appointments.json', []);
  const [healthyPatientsLength, setHealthyPatients] = useState(0)
  const [turbtRP, setTurbtRP] = useState("")
  const [bladderCancerLength, setBladderCancer] = useState(0)
  const [operationsLength, setOperationsLength] = useState(0)
  const [pathologyLength, setPathologyLength] = useState(0)
  const [completeUrinalysisLength, setCompleteUrinalysisLength] = useState(0)
  const [pathologyComplete, setPathologyComplete] = useState(0)
  const [patientsGender, setPatientsGender] = useState({})
  const [collectionMethod, setCollectionMethod] = useState({})
  const [age, setAge] = useState({})
  const [dayBeforeTurbtCount, setDayBeforeTurbt] = useState(0)
  const [dayBeforeTurbtCountRP, setDayBeforeTurbtRP] = useState("")
  const [missedData, setMissedData] = useState(0)
  const [urinalysisLength, setUrinalysisLength] = useState(0)
  const [labelGroup, setLabelGroup] = useState({})
  const [labelMultyClass, setLabelMultyClass] = useState({})
  const [labelDetailed, setLabelDetailed] = useState({})
  const [fastingUrineID, setFastingUrineID] = useState([])
  const [nonFastingUrineID, setNonFastingUrineID] = useState([])
  const [groupCancer, setGroupCancer] = useState([])
  const [nonGroupCancer, setNonGroupCancer] = useState([])
  const [labelGroupNonFast, setLabelGroupNonFast] = useState({})
  const [labelMultyClassNonFast, setLabelMultyClassNonFast] = useState({})
  const [labelDetailedNonFast, setLabelDetailedNonFast] = useState({})
  const [labelGroupFast, setLabelGroupFast] = useState({})
  const [labelMultyClassFast, setLabelMultyClassFast] = useState({})
  const [labelDetailedFast, setLabelDetailedFast] = useState({})
  const [visible, setVisible] = useState({ all: true, nonFast: false, fast: false })
  const [labelMultyClassArrays, setLabelMultyClassArrays] = useState({ nonCancer: [], hg: [], lg: [] })
  const [labelDetailedArrays, setLabelDetailedArrays] = useState([])
  const [permission, setPermission] = useState(false)
  const userGroups = useUserGroups();


  usePageData(pageData);

  const getFirstRunLength = (list) => {
    return list.filter((visit) => visit.Run_Number.S == "1").length
  }


  useEffect(() => {
    const start = async () => {
      const ageOpt = { "0-40": 0, "41-50": 0, "51-60": 0, "61-70": 0, "71-80": 0, "81+": 0 }
      const collectionMethods = ['Non Fasting Urine', '1st Morning Urine (Fasting)', '2nd+ Urine (Fasting)']
      const labelDetailedOpt = ["Normal Pathology", "Inflammation", "Ta-Low-Grade", "Ta-High-Grade", "Ta-High-Grade-CIS", "T1-High-Grade", "T1-High-Grade-CIS", "T2-High-Grade", "T2-High-Grade-CIS"]
      const permissionData = (await axios.post("http://54.174.123.25:8000/patients/", { f: "CheckPermission", userGroups: userGroups, permissions: ["CareGiver-Belinson-BladderCancer-Edit", "CareGiver-Belinson-BladderCancer-Review"] })).data
      setPermission(permissionData)

      // Parallel CRUD Operations
      const [nonFastingID, firstFastingID, seconedFastingID, labelMultiClassNonCancer, labelMultiClassHG, labelMultiClassLG, pathologyResp, urinalysisResp, groupCancer, groupNonCancer, maleNumber, femaleNumber, bladderCancerPatients, healthyPatients, turbtData, dayBeforeTurbtData, ...collectionResults] = await Promise.all([
        getSpecificUrineID('observations', ['Collection_Method'], ["Non Fasting Urine"], 'Collection_Method-index', false),
        getSpecificUrineID('observations', ['Collection_Method'], ["1st Morning Urine (Fasting)"], 'Collection_Method-index', false),
        getSpecificUrineID('observations', ['Collection_Method'], ["2nd+ Urine (Fasting)"], 'Collection_Method-index', false),
        getByQuery("pathology", ["Pathology Label Multi Class"], ["Non-Cancer"], 'Pathology-Label-Multi-Class-index', true),
        getByQuery("pathology", ["Pathology Label Multi Class"], ["HG"], 'Pathology-Label-Multi-Class-index', true),
        getByQuery("pathology", ["Pathology Label Multi Class"], ["LG"], 'Pathology-Label-Multi-Class-index', true),
        getFullItemsPathology("pathology"),
        getFullItemsUrinalysis("urinalysis"),
        getByQuery("pathology", ["Pathology Label Group"], ["Cancer"], 'Pathology-Label-Group-index', true),
        getByQuery("pathology", ["Pathology Label Group"], ["Non-Cancer"], 'Pathology-Label-Group-index', true),
        (await axios.post("http://54.174.123.25:8000/patients/", { f: "getPatientByQuery", fieldNames: ["Study_Population", "Gender"], conditions: ["Bladder Cancer", "Male"], IndexName: 'Study_Population-Gender-index' })).data,
        (await axios.post("http://54.174.123.25:8000/patients/", { f: "getPatientByQuery", fieldNames: ["Study_Population", "Gender"], conditions: ["Bladder Cancer", "Female"], IndexName: 'Study_Population-Gender-index' })).data,
        getByQuery("patients", ["Study_Population"], ["Bladder Cancer"], 'Study_Population-index', false),
        getByQuery("patients", ["Study_Population"], ["Healthy"], 'Study_Population-index', false),
        getByQuery("observations", ["Admit_Reason_Follow_Up"], ["Day 0-TURBT"], 'Admit_Reason_Follow_Up-index', false),
        getByQuery("observations", ["Admit_Reason_Follow_Up"], ["Day before TURBT"], 'Admit_Reason_Follow_Up-index', false),
        ...collectionMethods.map(async method => {
          let dataDayZero = await getByQuery('observations', ['Collection_Method', 'Admit_Reason_Follow_Up'], [method, "Day 0-TURBT"], 'Collection_Method-Admit_Reason_Follow_Up-index', false);
          let dataDayBefore = await getByQuery('observations', ['Collection_Method', 'Admit_Reason_Follow_Up'], [method, "Day before TURBT"], 'Collection_Method-Admit_Reason_Follow_Up-index', false);
          dataDayZero = dataDayZero.filter(observation => observation.Run_Number.S == "1")
          dataDayBefore = dataDayBefore.filter(observation => observation.Run_Number.S == "1")
          let dayZeroValue = dataDayZero ? dataDayZero.length : 0
          let DayBeforeValue = dataDayBefore ? dataDayBefore.length : 0
          return { [method]: dayZeroValue + DayBeforeValue };
        }),
      ]);

      // Get length of each value of Pathology Label Detailed
      let array = []
      const labelDetailedResults = await Promise.all(labelDetailedOpt.map(async label => {
        const data = await getByQuery("pathology", ["Pathology Label Detailed"], [label], 'Pathology-Label-Detailed-index', true);
        array.push(data)
        return { [label]: data ? data.length : 0 };
      }));

      // Set Relevent Data 
      setLabelDetailedArrays(array)
      setGroupCancer(groupCancer)
      setNonGroupCancer(groupNonCancer)
      setLabelMultyClassArrays({ nonCancer: labelMultiClassNonCancer, hg: labelMultiClassHG, lg: labelMultiClassLG })
      setNonFastingUrineID(nonFastingID)
      setFastingUrineID(firstFastingID.concat(seconedFastingID))

      // TURBT count + RP 
      let turbtCount = getFirstRunLength(turbtData)
      let turbtRPCount = turbtData.length - turbtCount

      // Day before TURBT count + RP
      let dayBeforeTurbtCount = getFirstRunLength(dayBeforeTurbtData)
      let dayBeforeTurbtCountRP = dayBeforeTurbtData.length - dayBeforeTurbtCount

      // Update patients gender array 
      patientsGenderOptions.series[0].data[0].value = femaleNumber
      patientsGenderOptions.series[0].data[1].value = maleNumber
      patientsGenderOptions.series[0].data[2].value = bladderCancerPatients.length - maleNumber - femaleNumber

      let dayBeforeTurbtCountRPStr = `+ ${dayBeforeTurbtCountRP} RP`
      let turbtRPCountStr = `+ ${turbtRPCount} RP`;
      const permission = (await axios.post("http://54.174.123.25:8000/patients/", { f: "CheckPermission", userGroups: userGroups, permissions: ["Admin", "RnD", "Mfg"] })).data
      // Only relevent users can see RP(repitition)
      if (!permission) {
        dayBeforeTurbtCountRPStr = ""
        turbtRPCountStr = ""
      }

      // Set RP 
      setDayBeforeTurbtRP(dayBeforeTurbtCountRPStr)
      setTurbtRP(turbtRPCountStr)

      // Set Length of Day before TURBT & TURBT 
      setOperationsLength(turbtCount)
      setDayBeforeTurbt(dayBeforeTurbtCount)

      // Set length of complete urinalysis and all urinalysis
      setCompleteUrinalysisLength(urinalysisResp.filteredLength)
      setUrinalysisLength(urinalysisResp.originalLength)

      // Set length of complete pathology and all pathology
      setPathologyLength(pathologyResp.originalLength)
      setPathologyComplete(pathologyResp.filteredLength)

      // Get now Date 
      let date = new Date(), year = date.getFullYear()
      let missedData = 0;

      bladderCancerPatients.forEach((patient) => {
        if (patient.Birth_Year) {
          // Calculate age distribution
          let age = year - parseInt(patient.Birth_Year.S)
          if (age <= 40) ageOpt["0-40"]++
          else if (age <= 50) ageOpt["41-50"]++
          else if (age <= 60) ageOpt["51-60"]++
          else if (age <= 70) ageOpt["61-70"]++
          else if (age <= 80) ageOpt["71-80"]++
          else ageOpt["81+"]++
        }
        // Calculate missed data
        if ((patient.Gender && patient.Gender.S == "") || patient.Birth_Year.S == "") {
          missedData++
        }
      })

      setMissedData(missedData)
      setHealthyPatients(healthyPatients.length)
      setBladderCancer(bladderCancerPatients.length)

      collectionMethodOptions.series[0].data.forEach((_collectionMetod, index) => _collectionMetod.value = collectionResults[index][_collectionMetod.name])
      patientsAgeOptions.series[0].data.forEach(_age => _age.value = ageOpt[_age.name])
      labelGroupOptions.series[0].data[1].value = groupCancer.length;
      labelGroupOptions.series[0].data[0].value = groupNonCancer.length;
      labelMultiClassOptions.series[0].data[0].value = labelMultiClassLG.length
      labelMultiClassOptions.series[0].data[1].value = labelMultiClassHG.length
      labelMultiClassOptions.series[0].data[2].value = labelMultiClassNonCancer.length
      pathologyOptions.series[0].data.forEach((item, index) => { if (item.name != "Other") item.value = labelDetailedResults[index][item.name] })
      addOtherParam(pathologyOptions.series[0].data, groupCancer.length + groupNonCancer.length)
      setCollectionMethod(collectionMethodOptions)
      setPatientsGender(patientsGenderOptions)
      setLabelGroup(labelGroupOptions)
      setAge(patientsAgeOptions)
      setLabelMultyClass(labelMultiClassOptions)
      setLabelDetailed(pathologyOptions)
    }
    start()
  }, [])

  const addOtherParam = (array, totalGroup) => {
    let allCount = 0, otherValue = 0;
    array.forEach((array) => { if (array.name != "Other") allCount += array.value })
    otherValue = totalGroup - allCount
    //other exist
    if (array[9])
      array[9].value = otherValue
    else
      array.push({ value: otherValue, name: "Other" },)
  }


  // Counter for graph values
  const graphCounter = (filterdArray, spesificGroup, arrayToDisplay) => {
    let counter = 0;
    spesificGroup.forEach((pathology) => {
      if (filterdArray.includes(pathology.Study_ID.S)) {
        counter++
      }
    })
    arrayToDisplay.value = counter
  }

  // Change graph to Non Fast Urine Values
  const getNonFastUrine = async () => {
    graphCounter(nonFastingUrineID, groupCancer, labelGroupOptions.series[0].data[1])
    graphCounter(nonFastingUrineID, nonGroupCancer, labelGroupOptions.series[0].data[0])
    graphCounter(nonFastingUrineID, labelMultyClassArrays.lg, labelMultiClassOptions.series[0].data[0])
    graphCounter(nonFastingUrineID, labelMultyClassArrays.hg, labelMultiClassOptions.series[0].data[1])
    graphCounter(nonFastingUrineID, labelMultyClassArrays.nonCancer, labelMultiClassOptions.series[0].data[2])

    labelDetailedArrays.forEach((array, index) => {
      const matchingData = pathologyOptions.series[0].data.find(
        (opt, index) => array[0] && array[0]["Pathology Label Detailed"].S === opt.name
      );
      if (matchingData) {
        graphCounter(nonFastingUrineID, array, matchingData);
      }
    });

    addOtherParam(pathologyOptions.series[0].data, labelGroupOptions.series[0].data[0].value + labelGroupOptions.series[0].data[1].value)
    setLabelGroupNonFast(labelGroupOptions)
    setLabelMultyClassNonFast(labelMultiClassOptions)
    setLabelDetailedNonFast(pathologyOptions)

    setVisible({ all: false, fast: false, nonFast: true })
  }

  // Change graph to Fast Urine Values(first & seconed)
  const getFastUrine = async () => {
    graphCounter(fastingUrineID, groupCancer, labelGroupOptions.series[0].data[1])
    graphCounter(fastingUrineID, nonGroupCancer, labelGroupOptions.series[0].data[0])
    graphCounter(fastingUrineID, labelMultyClassArrays.lg, labelMultiClassOptions.series[0].data[0])
    graphCounter(fastingUrineID, labelMultyClassArrays.hg, labelMultiClassOptions.series[0].data[1])
    graphCounter(fastingUrineID, labelMultyClassArrays.nonCancer, labelMultiClassOptions.series[0].data[2])

    labelDetailedArrays.forEach((array, index) => {
      const matchingData = pathologyOptions.series[0].data.find(
        (opt, index) => array[0] && array[0]["Pathology Label Detailed"].S === opt.name
      );
      if (matchingData) {
        graphCounter(fastingUrineID, array, matchingData);
      }
    });

    addOtherParam(pathologyOptions.series[0].data, labelGroupOptions.series[0].data[0].value + labelGroupOptions.series[0].data[1].value)
    setLabelGroupFast(labelGroupOptions)
    setLabelMultyClassFast(labelMultiClassOptions)
    setLabelDetailedFast(pathologyOptions)
    setVisible({ all: false, fast: true, nonFast: false })
  }

  // Change graph to all Urine Values
  const getAllUrine = () => {
    labelGroupOptions.series[0].data[0].value = nonGroupCancer.length
    labelGroupOptions.series[0].data[1].value = groupCancer.length

    labelMultiClassOptions.series[0].data[0].value = labelMultyClassArrays.lg.length
    labelMultiClassOptions.series[0].data[1].value = labelMultyClassArrays.hg.length
    labelMultiClassOptions.series[0].data[2].value = labelMultyClassArrays.nonCancer.length

    labelDetailedArrays.forEach((array, index) => {
      const matchingData = pathologyOptions.series[0].data.find(
        (opt, index) => array[0] && array[0]["Pathology Label Detailed"].S === opt.name
      );
      if (matchingData) {
        matchingData.value = array.length
      }
    });
    addOtherParam(pathologyOptions.series[0].data, groupCancer.length + nonGroupCancer.length)
    setLabelGroup(labelGroupOptions)
    setLabelMultyClass(labelMultiClassOptions)
    setLabelDetailed(pathologyOptions)
    setVisible({ all: true, fast: false, nonFast: false })
  }

  // Decoration
  let urinalysisPercent = urinalysisLength == 0 ? 0 : (completeUrinalysisLength / urinalysisLength) * 100
  let pathologyPercent = pathologyLength == 0 ? 0 : (pathologyComplete / pathologyLength) * 100
  let pathologyPercentColor = pathologyPercent < 50 ? "red" : "green"
  let urinalysisPercentColor = urinalysisPercent < 50 ? "red" : "green"
  let text = missedData > 0 ? <div style={{ color: "red", fontSize: 10 }}> Missed data ({missedData} patients) </div> : ""
  let all = <></>, nonFast = <></>, fast = <></>
  if (visible.all) all = <FaCheckCircle className='icon' />
  else if (visible.nonFast) nonFast = <FaCheckCircle className='icon' />
  else if (visible.fast) fast = <FaCheckCircle className='icon' />

  return (
    <>
      <div className='row'>
        {!permission &&
          (<div className='col-12 col-md-6 col-xl-4'>
            <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
              <div className='row'>
                <div className='col-5'>
                  <span
                    className='icofont icofont-gym'
                    style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                  />
                </div>

                <div className='col-7'>
                  <h6 className='mt-0 mb-1' >Healthy Volunteers</h6>
                  <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                    {healthyPatientsLength}
                  </div>
                </div>
              </div>
            </Card>
          </div>
          )}

        <div className='col-12 col-md-6 col-xl-4'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-paralysis-disability'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Bladder Cancer Patients</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {bladderCancerLength} {text}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className='row'>

        <div className='col-12 col-md-6 col-xl-4'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-icu'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>


              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Oncoredox Analysis <br />@ Day before TURBT</h6>
                <div className='count' style={{ fontSize: 18, color: '#336cfb', lineHeight: 1.4 }}>
                  {dayBeforeTurbtCount} {dayBeforeTurbtCountRP}
                </div>
              </div>
            </div>
          </Card>
        </div>



        <div className='col-12 col-md-6 col-xl-4'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-blood'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>


              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Oncoredox Analysis <br />@ TURBT</h6>
                <div className='count' style={{ fontSize: 17, color: '#336cfb', lineHeight: 1.4 }}>
                  {operationsLength} {turbtRP}
                </div>
              </div>
            </div>
          </Card>
        </div>

      </div >


      <div className='row'>
        <div className='col-12 col-md-6 col-xl-4'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont-patient-file'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Pathology Reports</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {pathologyComplete}/{pathologyLength} <div style={{ color: pathologyPercentColor }}>({pathologyPercent.toFixed(0)}%)</div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className='col-12 col-md-6 col-xl-4'>
          {/* <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-test-tube'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Urinalysis</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {completeUrinalysisLength}/{urinalysisLength} <div style={{ color: urinalysisPercentColor }}>({urinalysisPercent.toFixed(0)}%)</div>
                </div>
              </div>
            </div>
          </Card> */}
        </div>
      </div>

      <Card title='Patients Surveillance Timeline (in construction)' >
        <ReactEcharts className='chart-container container-h-400' option={hospitalOptions} />
      </Card>

      <div className='row'>

        <div className='col-12 col-md-4'>
          <Card title={'Age'}>
            <ReactEcharts className='chart-container container-h-300' option={age} />
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={'Gender'}>
            <ReactEcharts className='chart-container container-h-300' option={patientsGender} />
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={'Urine Type'}>
            <ReactEcharts className='chart-container container-h-300' option={collectionMethod} />
          </Card>
        </div>
      </div>

      {visible.all && <div className='row'>

        <div className='col-12 col-md-4'>
          <Card title={' '}>
            <ReactEcharts className='chart-container container-h-300' option={labelGroup} />
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={'Observations'}>
            <ReactEcharts className='chart-container container-h-300' option={labelMultyClass} />
          </Card>
        </div>


        <div className='col-12 col-md-4'>
          <Card title={' '}>
            <ReactEcharts className='chart-container container-h-300' option={labelDetailed} />
          </Card>
        </div>
      </div>
      }

      {visible.nonFast && <div className='row'>

        <div className='col-12 col-md-4'>
          <Card title={' '}>
            <ReactEcharts className='chart-container container-h-300' option={labelGroupNonFast} />
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={'Observations'}>
            <ReactEcharts className='chart-container container-h-300' option={labelMultyClassNonFast} />
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={' '}>
            <ReactEcharts className='chart-container container-h-300' option={labelDetailedNonFast} />
          </Card>
        </div>
      </div>
      }

      {visible.fast && <div className='row'>

        <div className='col-12 col-md-4'>
          <Card title={' '}>
            <ReactEcharts className='chart-container container-h-300' option={labelGroupFast} />
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={'Observations'}>
            <ReactEcharts className='chart-container container-h-300' option={labelMultyClassFast} />
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={' '}>
            <ReactEcharts className='chart-container container-h-300' option={labelDetailedFast} />
          </Card>
        </div>
      </div>
      }

      <div className='row'>

        <div className='col-12 mt-3'>
          <div className='square-container'>
            <button className='square-option' onClick={() => getAllUrine()}>
              {all} All Urine
            </button>
            <button className='square-option' onClick={() => getNonFastUrine()}>
              {nonFast} Non Fasting Urine
            </button>
            <button className='square-option' onClick={() => getFastUrine()}>
              {fast} Fasting Urine
            </button>
          </div>
        </div>
      </div >

    </>
  );
};

export default DashboardPage;














// Start moving code to backend
// import React, { useState, useEffect } from 'react';

// import { Card } from 'antd';

// import ReactEcharts from 'echarts-for-react';

// import hospitalOptions from './charts/hospital-options';
// import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import FontAwesome icons

// import {
//   patientsGenderOptions,
//   collectionMethodOptions,
//   patientsAgeOptions,
//   pathologyOptions,
//   labelGroupOptions,
//   labelMultiClassOptions
// } from './charts/patients-options';

// import { useFetchPageData, usePageData } from '../../../hooks/usePage';

// import { IAppointment } from '../../../interfaces/patient';
// import { IPageData } from '../../../interfaces/page';
// import { getFullItemsUrinalysis, getFullItemsPathology, getSpecificUrineID } from '../../../crud/visits';
// import { CheckPermission } from '../../../crud/amplify';
// import { useUserGroups } from '../../../UserGroupsContext';
// import axios from 'axios'

// const pageData: IPageData = {
//   fulFilled: false,
//   breadcrumbs: [
//     {
//       title: 'Dashboards',
//       route: 'default-dashboard'
//     },
//     {
//       title: 'Default'
//     }
//   ]
// };


// const DashboardPage = () => {
//   const [appointments] = useFetchPageData<IAppointment[]>('./data/last-appointments.json', []);
//   const [healthyPatientsLength, setHealthyPatients] = useState(0)
//   const [turbtRP, setTurbtRP] = useState("")
//   const [bladderCancerLength, setBladderCancer] = useState(0)
//   const [operationsLength, setOperationsLength] = useState(0)
//   const [pathologyLength, setPathologyLength] = useState(0)
//   const [completeUrinalysisLength, setCompleteUrinalysisLength] = useState(0)
//   const [pathologyComplete, setPathologyComplete] = useState(0)
//   const [patientsGender, setPatientsGender] = useState({})
//   const [collectionMethod, setCollectionMethod] = useState({})
//   const [age, setAge] = useState({})
//   const [dayBeforeTurbtCount, setDayBeforeTurbt] = useState(0)
//   const [dayBeforeTurbtCountRP, setDayBeforeTurbtRP] = useState("")
//   const [missedData, setMissedData] = useState(0)
//   const [urinalysisLength, setUrinalysisLength] = useState(0)
//   const [labelGroup, setLabelGroup] = useState({})
//   const [labelMultyClass, setLabelMultyClass] = useState({})
//   const [labelDetailed, setLabelDetailed] = useState({})
//   const [fastingUrineID, setFastingUrineID] = useState([])
//   const [nonFastingUrineID, setNonFastingUrineID] = useState([])
//   const [groupCancer, setGroupCancer] = useState([])
//   const [nonGroupCancer, setNonGroupCancer] = useState([])
//   const [labelGroupNonFast, setLabelGroupNonFast] = useState({})
//   const [labelMultyClassNonFast, setLabelMultyClassNonFast] = useState({})
//   const [labelDetailedNonFast, setLabelDetailedNonFast] = useState({})
//   const [labelGroupFast, setLabelGroupFast] = useState({})
//   const [labelMultyClassFast, setLabelMultyClassFast] = useState({})
//   const [labelDetailedFast, setLabelDetailedFast] = useState({})
//   const [visible, setVisible] = useState({ all: true, nonFast: false, fast: false })
//   const [labelMultyClassArrays, setLabelMultyClassArrays] = useState({ nonCancer: [], hg: [], lg: [] })
//   const [labelDetailedArrays, setLabelDetailedArrays] = useState([])
//   const userGroups = useUserGroups();


//   usePageData(pageData);

//   const getFirstRunLength = (list) => {
//     return list.filter((visit) => visit.Run_Number.S == "1").length
//   }


//   useEffect(() => {
//     const start = async () => {
//       const ageOpt = { "0-40": 0, "41-50": 0, "51-60": 0, "61-70": 0, "71-80": 0, "81+": 0 }
//       const collectionMethods = ['Non Fasting Urine', '1st Morning Urine (Fasting)', '2nd+ Urine (Fasting)']
//       const labelDetailedOpt = ["Normal Pathology", "Inflammation", "Ta-Low-Grade", "Ta-High-Grade", "Ta-High-Grade-CIS", "T1-High-Grade", "T1-High-Grade-CIS", "T2-High-Grade", "T2-High-Grade-CIS"]

//       // Parallel CRUD Operations
//       const [nonFastingID, firstFastingID, seconedFastingID, labelMultiClassNonCancer, labelMultiClassHG, labelMultiClassLG, pathologyResp, urinalysisResp, groupCancer, groupNonCancer, maleNumber, femaleNumber, bladderCancerPatients, healthyPatients, turbtData, dayBeforeTurbtData, ...collectionResults] = await Promise.all([
//         getSpecificUrineID('observations', ['Collection_Method'], ["Non Fasting Urine"], 'Collection_Method-index', false),
//         getSpecificUrineID('observations', ['Collection_Method'], ["1st Morning Urine (Fasting)"], 'Collection_Method-index', false),
//         getSpecificUrineID('observations', ['Collection_Method'], ["2nd+ Urine (Fasting)"], 'Collection_Method-index', false),
//         (await axios.post("http://54.174.123.25:8000/visits/", { f: "getByQuery", tableName: "pathology", fieldNames: ["Pathology Label Multi Class"], conditions: ["Non-Cancer"], IndexName: 'Pathology-Label-Multi-Class-index' })).data,
//         (await axios.post("http://54.174.123.25:8000/visits/", { f: "getByQuery", tableName: "pathology", fieldNames: ["Pathology Label Multi Class"], conditions: ["HG"], IndexName: 'Pathology-Label-Multi-Class-index' })).data,
//         (await axios.post("http://54.174.123.25:8000/visits/", { f: "getByQuery", tableName: "pathology", fieldNames: ["Pathology Label Multi Class"], conditions: ["LG"], IndexName: 'Pathology-Label-Multi-Class-index' })).data,
//         getFullItemsPathology("pathology"),
//         getFullItemsUrinalysis("urinalysis"),
//         (await axios.post("http://54.174.123.25:8000/visits/", { f: "getByQuery", tableName: "pathology", fieldNames: ["Pathology Label Group"], conditions: ["Cancer"], IndexName: 'Pathology-Label-Group-index' })).data,
//         (await axios.post("http://54.174.123.25:8000/visits/", { f: "getByQuery", tableName: "pathology", fieldNames: ["Pathology Label Group"], conditions: ["Non-Cancer"], IndexName: 'Pathology-Label-Group-index' })).data,
//         (await axios.post("http://54.174.123.25:8000/patients/", { f: "getPatientByQuery", fieldNames: ["Study_Population", "Gender"], conditions: ["Bladder Cancer", "Male"], IndexName: 'Study_Population-Gender-index' })).data,
//         (await axios.post("http://54.174.123.25:8000/patients/", { f: "getPatientByQuery", fieldNames: ["Study_Population", "Gender"], conditions: ["Bladder Cancer", "Female"], IndexName: 'Study_Population-Gender-index' })).data,
//         (await axios.post("http://54.174.123.25:8000/visits/", { f: "getByQuery", tableName: "patients", fieldNames: ["Study_Population"], conditions: ["Bladder Cancer"], IndexName: 'Study_Population-index' })).data,
//         ...collectionMethods.map(async method => {
//           let dataDayZero = (await axios.post("http://54.174.123.25:8000/visits/", { f: "getByQuery", tableName: "observations", fieldNames: ['Collection_Method', 'Admit_Reason_Follow_Up'], conditions: [method, "Day 0-TURBT"], IndexName: 'Collection_Method-Admit_Reason_Follow_Up-index' })).data
//           let dataDayBefore = (await axios.post("http://54.174.123.25:8000/visits/", { f: "getByQuery", tableName: "observations", fieldNames: ['Collection_Method', 'Admit_Reason_Follow_Up'], conditions: [method, "Day before TURBT"], IndexName: 'Collection_Method-Admit_Reason_Follow_Up-index' })).data
//           dataDayZero = dataDayZero.filter(observation => observation.Run_Number.S == "1")
//           dataDayBefore = dataDayBefore.filter(observation => observation.Run_Number.S == "1")
//           let dayZeroValue = dataDayZero ? dataDayZero.length : 0
//           let DayBeforeValue = dataDayBefore ? dataDayBefore.length : 0
//           return { [method]: dayZeroValue + DayBeforeValue };
//         }),
//       ]);

//       // Get length of each value of Pathology Label Detailed
//       let array = []
//       const labelDetailedResults = await Promise.all(labelDetailedOpt.map(async label => {
//         const data = (await axios.post("http://54.174.123.25:8000/visits/", { f: "getByQuery", tableName: "pathology", fieldNames: ['Pathology Label Detailed'], conditions: [label], IndexName: 'Pathology-Label-Detailed-index' })).data
//         array.push(data)
//         return { [label]: data ? data.length : 0 };
//       }));

//       // Set Relevent Data
//       setLabelDetailedArrays(array)
//       setGroupCancer(groupCancer)
//       setNonGroupCancer(groupNonCancer)
//       setLabelMultyClassArrays({ nonCancer: labelMultiClassNonCancer, hg: labelMultiClassHG, lg: labelMultiClassLG })
//       setNonFastingUrineID(nonFastingID)
//       setFastingUrineID(firstFastingID.concat(seconedFastingID))

//       // TURBT count + RP
//       let turbtCount = getFirstRunLength(turbtData)
//       let turbtRPCount = turbtData.length - turbtCount

//       // Day before TURBT count + RP
//       let dayBeforeTurbtCount = getFirstRunLength(dayBeforeTurbtData)
//       let dayBeforeTurbtCountRP = dayBeforeTurbtData.length - dayBeforeTurbtCount

//       // Update patients gender array
//       patientsGenderOptions.series[0].data[0].value = femaleNumber
//       patientsGenderOptions.series[0].data[1].value = maleNumber
//       patientsGenderOptions.series[0].data[2].value = bladderCancerPatients.length - maleNumber - femaleNumber

//       let dayBeforeTurbtCountRPStr = `+ ${dayBeforeTurbtCountRP} RP`
//       let turbtRPCountStr = `+ ${turbtRPCount} RP`;
//       let permission = CheckPermission(userGroups, ["Admin", "RnD", "Mfg"])
//       // Only relevent users can see RP(repitition)
//       if (!permission) {
//         dayBeforeTurbtCountRPStr = ""
//         turbtRPCountStr = ""
//       }

//       // Set RP
//       setDayBeforeTurbtRP(dayBeforeTurbtCountRPStr)
//       setTurbtRP(turbtRPCountStr)

//       // Set Length of Day before TURBT & TURBT
//       setOperationsLength(turbtCount)
//       setDayBeforeTurbt(dayBeforeTurbtCount)

//       // Set length of complete urinalysis and all urinalysis
//       setCompleteUrinalysisLength(urinalysisResp.filteredLength)
//       setUrinalysisLength(urinalysisResp.originalLength)

//       // Set length of complete pathology and all pathology
//       setPathologyLength(pathologyResp.originalLength)
//       setPathologyComplete(pathologyResp.filteredLength)

//       // Get now Date
//       let date = new Date(), year = date.getFullYear()
//       let missedData = 0;

//       bladderCancerPatients.forEach((patient) => {
//         if (patient.Birth_Year) {
//           // Calculate age distribution
//           let age = year - parseInt(patient.Birth_Year.S)
//           if (age <= 40) ageOpt["0-40"]++
//           else if (age <= 50) ageOpt["41-50"]++
//           else if (age <= 60) ageOpt["51-60"]++
//           else if (age <= 70) ageOpt["61-70"]++
//           else if (age <= 80) ageOpt["71-80"]++
//           else ageOpt["81+"]++
//         }
//         // Calculate missed data
//         if ((patient.Gender && patient.Gender.S == "") || patient.Birth_Year.S == "") {
//           missedData++
//         }
//       })

//       setMissedData(missedData)
//       setHealthyPatients(healthyPatients.length)
//       setBladderCancer(bladderCancerPatients.length)

//       collectionMethodOptions.series[0].data.forEach((_collectionMetod, index) => _collectionMetod.value = collectionResults[index][_collectionMetod.name])
//       patientsAgeOptions.series[0].data.forEach(_age => _age.value = ageOpt[_age.name])
//       labelGroupOptions.series[0].data[1].value = groupCancer.length;
//       labelGroupOptions.series[0].data[0].value = groupNonCancer.length;
//       labelMultiClassOptions.series[0].data[0].value = labelMultiClassLG.length
//       labelMultiClassOptions.series[0].data[1].value = labelMultiClassHG.length
//       labelMultiClassOptions.series[0].data[2].value = labelMultiClassNonCancer.length
//       pathologyOptions.series[0].data.forEach((item, index) => { if (item.name != "Other") item.value = labelDetailedResults[index][item.name] })
//       addOtherParam(pathologyOptions.series[0].data, groupCancer.length + groupNonCancer.length)
//       setCollectionMethod(collectionMethodOptions)
//       setPatientsGender(patientsGenderOptions)
//       setLabelGroup(labelGroupOptions)
//       setAge(patientsAgeOptions)
//       setLabelMultyClass(labelMultiClassOptions)
//       setLabelDetailed(pathologyOptions)
//     }
//     start()
//   }, [])

//   const addOtherParam = (array, totalGroup) => {
//     let allCount = 0, otherValue = 0;
//     array.forEach((array) => { if (array.name != "Other") allCount += array.value })
//     otherValue = totalGroup - allCount
//     //other exist
//     if (array[9])
//       array[9].value = otherValue
//     else
//       array.push({ value: otherValue, name: "Other" },)
//   }


//   // Counter for graph values
//   const graphCounter = (filterdArray, spesificGroup, arrayToDisplay) => {
//     let counter = 0;
//     spesificGroup.forEach((pathology) => {
//       if (filterdArray.includes(pathology.Study_ID.S)) {
//         counter++
//       }
//     })
//     arrayToDisplay.value = counter
//   }

//   // Change graph to Non Fast Urine Values
//   const getNonFastUrine = async () => {
//     graphCounter(nonFastingUrineID, groupCancer, labelGroupOptions.series[0].data[1])
//     graphCounter(nonFastingUrineID, nonGroupCancer, labelGroupOptions.series[0].data[0])
//     graphCounter(nonFastingUrineID, labelMultyClassArrays.lg, labelMultiClassOptions.series[0].data[0])
//     graphCounter(nonFastingUrineID, labelMultyClassArrays.hg, labelMultiClassOptions.series[0].data[1])
//     graphCounter(nonFastingUrineID, labelMultyClassArrays.nonCancer, labelMultiClassOptions.series[0].data[2])

//     labelDetailedArrays.forEach((array, index) => {
//       const matchingData = pathologyOptions.series[0].data.find(
//         (opt, index) => array[0] && array[0]["Pathology Label Detailed"].S === opt.name
//       );
//       if (matchingData) {
//         graphCounter(nonFastingUrineID, array, matchingData);
//       }
//     });

//     addOtherParam(pathologyOptions.series[0].data, labelGroupOptions.series[0].data[0].value + labelGroupOptions.series[0].data[1].value)
//     setLabelGroupNonFast(labelGroupOptions)
//     setLabelMultyClassNonFast(labelMultiClassOptions)
//     setLabelDetailedNonFast(pathologyOptions)

//     setVisible({ all: false, fast: false, nonFast: true })
//   }

//   // Change graph to Fast Urine Values(first & seconed)
//   const getFastUrine = async () => {
//     graphCounter(fastingUrineID, groupCancer, labelGroupOptions.series[0].data[1])
//     graphCounter(fastingUrineID, nonGroupCancer, labelGroupOptions.series[0].data[0])
//     graphCounter(fastingUrineID, labelMultyClassArrays.lg, labelMultiClassOptions.series[0].data[0])
//     graphCounter(fastingUrineID, labelMultyClassArrays.hg, labelMultiClassOptions.series[0].data[1])
//     graphCounter(fastingUrineID, labelMultyClassArrays.nonCancer, labelMultiClassOptions.series[0].data[2])

//     labelDetailedArrays.forEach((array, index) => {
//       const matchingData = pathologyOptions.series[0].data.find(
//         (opt, index) => array[0] && array[0]["Pathology Label Detailed"].S === opt.name
//       );
//       if (matchingData) {
//         graphCounter(fastingUrineID, array, matchingData);
//       }
//     });

//     addOtherParam(pathologyOptions.series[0].data, labelGroupOptions.series[0].data[0].value + labelGroupOptions.series[0].data[1].value)
//     setLabelGroupFast(labelGroupOptions)
//     setLabelMultyClassFast(labelMultiClassOptions)
//     setLabelDetailedFast(pathologyOptions)
//     setVisible({ all: false, fast: true, nonFast: false })
//   }

//   // Change graph to all Urine Values
//   const getAllUrine = () => {
//     labelGroupOptions.series[0].data[0].value = nonGroupCancer.length
//     labelGroupOptions.series[0].data[1].value = groupCancer.length

//     labelMultiClassOptions.series[0].data[0].value = labelMultyClassArrays.lg.length
//     labelMultiClassOptions.series[0].data[1].value = labelMultyClassArrays.hg.length
//     labelMultiClassOptions.series[0].data[2].value = labelMultyClassArrays.nonCancer.length

//     labelDetailedArrays.forEach((array, index) => {
//       const matchingData = pathologyOptions.series[0].data.find(
//         (opt, index) => array[0] && array[0]["Pathology Label Detailed"].S === opt.name
//       );
//       if (matchingData) {
//         matchingData.value = array.length
//       }
//     });
//     addOtherParam(pathologyOptions.series[0].data, groupCancer.length + nonGroupCancer.length)
//     setLabelGroup(labelGroupOptions)
//     setLabelMultyClass(labelMultiClassOptions)
//     setLabelDetailed(pathologyOptions)
//     setVisible({ all: true, fast: false, nonFast: false })
//   }

//   // Decoration
//   let urinalysisPercent = urinalysisLength == 0 ? 0 : (completeUrinalysisLength / urinalysisLength) * 100
//   let pathologyPercent = pathologyLength == 0 ? 0 : (pathologyComplete / pathologyLength) * 100
//   let pathologyPercentColor = pathologyPercent < 50 ? "red" : "green"
//   let urinalysisPercentColor = urinalysisPercent < 50 ? "red" : "green"
//   let text = missedData > 0 ? <div style={{ color: "red", fontSize: 10 }}> Missed data ({missedData} patients) </div> : ""
//   let all = <></>, nonFast = <></>, fast = <></>
//   if (visible.all) all = <FaCheckCircle className='icon' />
//   else if (visible.nonFast) nonFast = <FaCheckCircle className='icon' />
//   else if (visible.fast) fast = <FaCheckCircle className='icon' />

//   return (
//     <>
//       <div className='row'>
//         {!CheckPermission(userGroups, ["CareGiver-Belinson-BladderCancer-Edit", "CareGiver-Belinson-BladderCancer-Review"]) &&
//           (<div className='col-12 col-md-6 col-xl-4'>
//             <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
//               <div className='row'>
//                 <div className='col-5'>
//                   <span
//                     className='icofont icofont-gym'
//                     style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
//                   />
//                 </div>

//                 <div className='col-7'>
//                   <h6 className='mt-0 mb-1' >Healthy Volunteers</h6>
//                   <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
//                     {healthyPatientsLength}
//                   </div>
//                 </div>
//               </div>
//             </Card>
//           </div>
//           )}

//         <div className='col-12 col-md-6 col-xl-4'>
//           <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
//             <div className='row'>
//               <div className='col-5'>
//                 <span
//                   className='icofont icofont-paralysis-disability'
//                   style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
//                 />
//               </div>

//               <div className='col-7'>
//                 <h6 className='mt-0 mb-1'>Bladder Cancer Patients</h6>
//                 <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
//                   {bladderCancerLength} {text}
//                 </div>
//               </div>
//             </div>
//           </Card>
//         </div>
//       </div>

//       <div className='row'>

//         <div className='col-12 col-md-6 col-xl-4'>
//           <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
//             <div className='row'>
//               <div className='col-5'>
//                 <span
//                   className='icofont icofont-icu'
//                   style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
//                 />
//               </div>


//               <div className='col-7'>
//                 <h6 className='mt-0 mb-1'>Oncoredox Analysis <br />@ Day before TURBT</h6>
//                 <div className='count' style={{ fontSize: 18, color: '#336cfb', lineHeight: 1.4 }}>
//                   {dayBeforeTurbtCount} {dayBeforeTurbtCountRP}
//                 </div>
//               </div>
//             </div>
//           </Card>
//         </div>



//         <div className='col-12 col-md-6 col-xl-4'>
//           <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
//             <div className='row'>
//               <div className='col-5'>
//                 <span
//                   className='icofont icofont-blood'
//                   style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
//                 />
//               </div>


//               <div className='col-7'>
//                 <h6 className='mt-0 mb-1'>Oncoredox Analysis <br />@ TURBT</h6>
//                 <div className='count' style={{ fontSize: 17, color: '#336cfb', lineHeight: 1.4 }}>
//                   {operationsLength} {turbtRP}
//                 </div>
//               </div>
//             </div>
//           </Card>
//         </div>

//       </div >


//       <div className='row'>
//         <div className='col-12 col-md-6 col-xl-4'>
//           <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
//             <div className='row'>
//               <div className='col-5'>
//                 <span
//                   className='icofont-patient-file'
//                   style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
//                 />
//               </div>

//               <div className='col-7'>
//                 <h6 className='mt-0 mb-1'>Pathology Reports</h6>
//                 <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
//                   {pathologyComplete}/{pathologyLength} <div style={{ color: pathologyPercentColor }}>({pathologyPercent.toFixed(0)}%)</div>
//                 </div>
//               </div>
//             </div>
//           </Card>
//         </div>
//         <div className='col-12 col-md-6 col-xl-4'>
//           {/* <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
//             <div className='row'>
//               <div className='col-5'>
//                 <span
//                   className='icofont icofont-test-tube'
//                   style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
//                 />
//               </div>

//               <div className='col-7'>
//                 <h6 className='mt-0 mb-1'>Urinalysis</h6>
//                 <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
//                   {completeUrinalysisLength}/{urinalysisLength} <div style={{ color: urinalysisPercentColor }}>({urinalysisPercent.toFixed(0)}%)</div>
//                 </div>
//               </div>
//             </div>
//           </Card> */}
//         </div>
//       </div>

//       <Card title='Patients Surveillance Timeline (in construction)' >
//         <ReactEcharts className='chart-container container-h-400' option={hospitalOptions} />
//       </Card>

//       <div className='row'>

//         <div className='col-12 col-md-4'>
//           <Card title={'Age'}>
//             <ReactEcharts className='chart-container container-h-300' option={age} />
//           </Card>
//         </div>

//         <div className='col-12 col-md-4'>
//           <Card title={'Gender'}>
//             <ReactEcharts className='chart-container container-h-300' option={patientsGender} />
//           </Card>
//         </div>

//         <div className='col-12 col-md-4'>
//           <Card title={'Urine Type'}>
//             <ReactEcharts className='chart-container container-h-300' option={collectionMethod} />
//           </Card>
//         </div>
//       </div>

//       {visible.all && <div className='row'>

//         <div className='col-12 col-md-4'>
//           <Card title={' '}>
//             <ReactEcharts className='chart-container container-h-300' option={labelGroup} />
//           </Card>
//         </div>

//         <div className='col-12 col-md-4'>
//           <Card title={'Observations'}>
//             <ReactEcharts className='chart-container container-h-300' option={labelMultyClass} />
//           </Card>
//         </div>


//         <div className='col-12 col-md-4'>
//           <Card title={' '}>
//             <ReactEcharts className='chart-container container-h-300' option={labelDetailed} />
//           </Card>
//         </div>
//       </div>
//       }

//       {visible.nonFast && <div className='row'>

//         <div className='col-12 col-md-4'>
//           <Card title={' '}>
//             <ReactEcharts className='chart-container container-h-300' option={labelGroupNonFast} />
//           </Card>
//         </div>

//         <div className='col-12 col-md-4'>
//           <Card title={'Observations'}>
//             <ReactEcharts className='chart-container container-h-300' option={labelMultyClassNonFast} />
//           </Card>
//         </div>

//         <div className='col-12 col-md-4'>
//           <Card title={' '}>
//             <ReactEcharts className='chart-container container-h-300' option={labelDetailedNonFast} />
//           </Card>
//         </div>
//       </div>
//       }

//       {visible.fast && <div className='row'>

//         <div className='col-12 col-md-4'>
//           <Card title={' '}>
//             <ReactEcharts className='chart-container container-h-300' option={labelGroupFast} />
//           </Card>
//         </div>

//         <div className='col-12 col-md-4'>
//           <Card title={'Observations'}>
//             <ReactEcharts className='chart-container container-h-300' option={labelMultyClassFast} />
//           </Card>
//         </div>

//         <div className='col-12 col-md-4'>
//           <Card title={' '}>
//             <ReactEcharts className='chart-container container-h-300' option={labelDetailedFast} />
//           </Card>
//         </div>
//       </div>
//       }

//       <div className='row'>

//         <div className='col-12 mt-3'>
//           <div className='square-container'>
//             <button className='square-option' onClick={() => getAllUrine()}>
//               {all} All Urine
//             </button>
//             <button className='square-option' onClick={() => getNonFastUrine()}>
//               {nonFast} Non Fasting Urine
//             </button>
//             <button className='square-option' onClick={() => getFastUrine()}>
//               {fast} Fasting Urine
//             </button>
//           </div>
//         </div>
//       </div >

//     </>
//   );
// };

// export default DashboardPage;
