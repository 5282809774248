
import React, { useState, useEffect } from 'react';

import { Button, Select, Input } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../../utils/hasError';
import { IPatient } from '../../../interfaces/patient';
import axios from 'axios';
import patientInfoOpt from '../../../Config/PatientInfoOpt.json'


const { TextArea } = Input;
type Props = {
  onSubmit: (patient: IPatient) => void;
  onCancel: () => void;
  patient?: IPatient;
  submitText?: string;
  onDataUpdated?: () => void;
  isEdit?: boolean;
};

const defaultSubmitText = 'Add patient';
const emptyPatient = {
  name: null,
  address: null,
  status: null,
  age: null,
  number: null,
  gender: null,
  img: null
};

const patientScheme = Yup.object({
  name: Yup.string().required(),
  address: Yup.string().required(),
  status: Yup.string().required(),
  age: Yup.string().required(),
  number: Yup.string().required(),
  gender: Yup.string().required(),
  img: Yup.string().required()
});

const PatientForm = ({
  submitText = defaultSubmitText,
  patient = emptyPatient,
  onSubmit,
  onCancel,
  onDataUpdated,
  isEdit,
}: Props) => {
  const {
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleSubmit,
    setValues,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid,

  } = useFormik<IPatient>({
    validationSchema: patientScheme,
    initialValues: patient,
    onSubmit: (values) => {
      onSubmit(values);
      onCancel()
    }
  });

  const handleGenderSelect = (value) => setFieldValue('gender', value);
  const handleStatusSelect = (value) => setFieldValue('status', value);
  const [ID, setID] = useState("")
  const [Birth_Year, setYear] = useState("")
  const [Gender, setGender] = useState("")
  const [Study_Population, setStudyPopulation] = useState("")
  const [Medical_Site, setMedicalSite] = useState("")
  const hasError = hasErrorFactory(touched, errors);
  const [disabled, setDisabled] = useState(false)
  const [add, isAdd] = useState(false)
  const patientLink = "http://localhost:8000/patients/"

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleImageLoad = (img) => {
    setValues({ ...values, img });
  };

  useEffect(() => {
    if (patient.Study_ID) setID(patient.Study_ID.S)
    if (patient.Birth_Year) setYear(patient.Birth_Year.S)
    if (patient.Gender) setGender(patient.Gender.S)
    if (patient.Study_Population) setStudyPopulation(patient.Study_Population.S)
    if (patient.Medical_Site) setMedicalSite(patient.Medical_Site.S)
    setDisabled(true)
  }, [patient])

  let checkOperation = () => {
    let newValues = {
      Gender: Gender,
      Birth_Year: Birth_Year,
      Study_Population: Study_Population,
      Medical_Site: Medical_Site
    }
    isEdit ? updatePatient(newValues) : addPatient(newValues)
    onCancel()
  }

  let updatePatient = async (newValues) => {
    try {
      await axios.post("http://54.174.123.25:8000/patients/", { f: "Put", itemId: patient.Study_ID.S, newValues: newValues })
      onDataUpdated(); // Call the refreshData function to update the table
    }
    catch {
      console.log("Failed to edit patient")
    }
  }

  let addPatient = async (newValues) => {
    try {
      let newPatient = { ...newValues, Study_ID: ID }
      // await Post(newPatient)
      //onDataUpdated(); // Call the refreshData function to update the table
    }
    catch {
      console.log("Failed to add patient")
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        {/* <div className='form-group'>
          <ImageLoader onLoad={handleImageLoad} src={values.img as string} />
        </div> */}

        <div className='form-group'>
          <Input
            placeholder={`Study ID: ${patient.Study_ID.S}`}
            name='studyID'
            type='text'
            onBlur={handleBlur}
            disabled={true}
            onChange={(e) => { setID(e.target.value) }} //need to change?
          // defaultValue={ID}
          // className={hasError('name')}
          />
        </div>


        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='birthYear'
                type='text'
                placeholder='Birth Year'
                onBlur={handleBlur}
                onChange={(e) => setYear(e.target.value)}
                value={Birth_Year}
              // defaultValue={Birth_Year}
              // className={hasError('age')}
              />
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                value={Gender}
                placeholder='Gender'
                // defaultValue={Gender}
                onChange={(value) => setGender(value)}
                className={hasError('gender')}
                onBlur={() => setFieldTouched('gender')}
              >
                {patientInfoOpt.gender.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
              </Select>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                placeholder='Study Population'
                value={patient.Study_Population.S}
                onChange={(value) => setStudyPopulation(value)}
                onBlur={handleBlur}
                disabled={disabled}
              // className={hasError('age')}
              >
                {patientInfoOpt.study_population.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
              </Select>
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                placeholder='Medical Site'
                value={patient.Medical_Site.S}
                onChange={(value) => setMedicalSite(value)}
                // className={hasError('gender')}
                onBlur={handleBlur}
                disabled={disabled}
              >
                {patientInfoOpt.medical_site.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
              </Select>
            </div>
          </div>
        </div>


        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button type='primary' htmlType='submit' onClick={() => checkOperation()}>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default PatientForm;

