import envi from '../Config/table.json'
import axios from 'axios';

export const Get = async () => {
    const params = {
        TableName: `oncoredox-sensor-${envi.enviroment}`,
    };

    const allItems = [];
    return new Promise(async (resolve, reject) => {
        const scan = async (startKey) => {
            if (startKey) {
                params.ExclusiveStartKey = startKey;
            }

            try {
                const result = (await axios.post("http://54.174.123.25:8000/visits/", { f: "scan", param: params })).data
                allItems.push(...result.Items);

                if (result.LastEvaluatedKey) {
                    // There are more items to fetch, recursively call scan
                    await scan(result.LastEvaluatedKey);
                } else {
                    // No more items to fetch, resolve with all items
                    resolve(allItems);
                }
            } catch (error) {
                console.error('Error scanning table:', error);
                reject(error);
            }
        };
        await scan();
    });
};


