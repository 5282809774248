import React, { useState, useEffect } from 'react';

import { Button, Select, Input } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ImageLoader from './ImageLoader';
import { hasErrorFactory } from '../../../utils/hasError';

import { IPatient } from '../../../interfaces/patient';
import axios from 'axios';
import { Console, log } from 'console';
import urinaryOpt from '../../../Config/urinalysisOpt.json'
import links from '../../../Config/links.json'
import { Put } from '../../../crud/visits';

const { TextArea } = Input;
type Props = {
  onSubmit: (patient: IPatient) => void;
  onCancel: () => void;
  patient?: IPatient;
  submitText?: string;
  onDataUpdated?: (record) => void;
  isEdit?: boolean;
};

const defaultSubmitText = 'Add patient';
const emptyPatient = {
  name: null,
  address: null,
  status: null,
  age: null,
  number: null,
  gender: null,
  img: null
};

const patientScheme = Yup.object({
  name: Yup.string().required(),
  address: Yup.string().required(),
  status: Yup.string().required(),
  age: Yup.string().required(),
  number: Yup.string().required(),
  gender: Yup.string().required(),
  img: Yup.string().required()
});

const EditUrinaryVisit = ({
  submitText = defaultSubmitText,
  patient = emptyPatient,
  onSubmit,
  onCancel,
  onDataUpdated,
  isEdit,
}: Props) => {
  const {
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleSubmit,
    setValues,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid,

  } = useFormik<IPatient>({
    validationSchema: patientScheme,
    initialValues: patient,
    onSubmit: (values) => {
      onSubmit(values);
      onCancel()
    }
  });

  const handleGenderSelect = (value) => setFieldValue('gender', value);
  const handleStatusSelect = (value) => setFieldValue('status', value);
  const [ph, setPh] = useState("")
  const [glucose, setGlucose] = useState("")
  const [protein, setProtein] = useState("")
  const [RBC, setRBC] = useState("")
  const [bilirubin, setBilirubin] = useState("")
  const [ketones, setKetones] = useState("")
  const [GRAV, setGRAV] = useState("")
  const [nitrite, setNitrite] = useState("")
  const [leucocytes, setLeucocytes] = useState("")
  const [urobilinoge, setUrobilinoge] = useState("")
  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleImageLoad = (img) => {
    setValues({ ...values, img });
  };

  let updateVisit = async () => {
    try {
      let newValues = {
        Study_ID: patient.Study_ID,
        Visit_Date: patient.Visit_Date,
        pH: { S: ph },
        Glucose: { S: glucose },
        Protein: { S: protein },
        RBC: { S: RBC },
        bilirubin: { S: bilirubin }, 
        ketones: { S: ketones }, 
        GRAV: { S: GRAV },
        nitrite: { S: nitrite }, 
        leucocytes: { S: leucocytes }, 
        urobilinoge: { S: urobilinoge }, 
      }
      console.log("new values", newValues)
      await Put(patient.Study_ID.S, newValues, "urinalysis")
      onDataUpdated(newValues)
      onCancel()
    }
    catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (patient.pH) setPh(patient.pH.S);
    if (patient.Glucose) setGlucose(patient.Glucose.S);
    if (patient.Protein) setProtein(patient.Protein.S);
    if (patient.RBC) setRBC(patient.RBC.S);
    if (patient.Bilirubin) setBilirubin(patient.Bilirubin.S);
    if (patient.Ketones) setKetones(patient.Ketones.S);
    if (patient.GRAV) setGRAV(patient.GRAV.S);
    if (patient.Nitrite) setNitrite(patient.Nitrite.S);
    if (patient.Leucocytes) setLeucocytes(patient.Leucocytes.S);
    if (patient.Urobilinoge) setUrobilinoge(patient.Urobilinoge.S);
  }, [patient])
  return (
    <>
      <form onSubmit={handleSubmit}>
        {/* <div className='form-group'>
          <ImageLoader onLoad={handleImageLoad} src={values.img as string} />
        </div> */}

        <div className='form-group'>
          <Input
            placeholder={`Study ID: ${patient.Study_ID.S}`}
            name='studyID'
            type='text'
            onBlur={handleBlur}
            disabled={true}
          />
        </div>


        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='pH'
                type='text'
                onBlur={handleBlur}
                onChange={(e) => { setPh(e.target.value) }}
                placeholder={'pH: '}
                value={ph}
              />
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='bilirubin'
                type='text'
                onBlur={handleBlur}
                onChange={(e) => { setBilirubin(e.target.value) }}
                placeholder={'Bilirubin: '}
                value={bilirubin}
              />
            </div>
          </div>
        </div>


        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='glucose'
                type='text'
                onBlur={handleBlur}
                onChange={(e) => { setGlucose(e.target.value) }}
                placeholder={'Glucose: '}
                value={glucose}
              />
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='protein'
                type='text'
                onBlur={handleBlur}
                onChange={(e) => { setProtein(e.target.value) }}
                placeholder={'Protein: '}
                value={protein}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='katones'
                type='text'
                onBlur={handleBlur}
                onChange={(e) => { setKetones(e.target.value) }}
                placeholder={'Ketones: '}
                value={ketones}
              />
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='GRAV'
                type='text'
                onBlur={handleBlur}
                onChange={(e) => { setGRAV(e.target.value) }}
                placeholder={'Specific Gravity: '}
                value={GRAV}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='nitrite'
                type='text'
                onBlur={handleBlur}
                onChange={(e) => { setNitrite(e.target.value) }}
                placeholder={'Nitrite: '}
                value={nitrite}
              />
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='leucocytes'
                type='text'
                onBlur={handleBlur}
                onChange={(e) => { setLeucocytes(e.target.value) }}
                placeholder={'Leucocytes: '}
                value={leucocytes}
              />
            </div>
          </div>
        </div>


        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='RBC'
                type='text'
                onBlur={handleBlur}
                onChange={(e) => { setRBC(e.target.value) }}
                placeholder={'RBC: '}
                value={RBC}
              />
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input
                name='urobilinoge'
                type='text'
                onBlur={handleBlur}
                onChange={(e) => { setUrobilinoge(e.target.value) }}
                placeholder={'Urobilinoge: '}
                value={urobilinoge}
              />
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button type='primary' htmlType='submit' onClick={() => updateVisit()}>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditUrinaryVisit;

