import axios from 'axios';
import envi from '../Config/table.json'

// need to add logic to sensors for delete this func
export const GetVisit = async (tableName) => {
    const params = {
        TableName: `oncoredox-${tableName}-${envi.enviroment}`,
    };

    const allItems = [];

    const maxRetries = 3; // Maximum number of retries
    let retries = 0;

    return new Promise(async (resolve, reject) => {
        const scan = async (startKey) => {
            if (startKey) {
                params.ExclusiveStartKey = startKey;
            }

            try {
                const result = (await axios.post("http://54.174.123.25:8000/visits/", { f: "scan", param: params })).data
                allItems.push(...result.Items);

                if (result.LastEvaluatedKey) {
                    // There are more items to fetch, recursively call scan
                    await scan(result.LastEvaluatedKey);
                } else {
                    // No more items to fetch, resolve with all items
                    resolve(allItems);
                }
            } catch (error) {
                if (error.code === 'ProvisionedThroughputExceededException' && retries < maxRetries) {
                    // Throttling error, apply exponential backoff and retry
                    const delayMs = Math.pow(2, retries) * 100;
                    retries++;
                    console.warn(`Throttling error, retrying in ${delayMs} ms...`);
                    setTimeout(() => scan(startKey), delayMs);
                } else {
                    console.error(`Error scanning table 'oncoredox-${tableName}-${envi.enviroment}':`, error);
                    reject(error);
                }
            }
        };

        await scan();
    });
};

export const getByQuery = async (tableName, fieldNames, conditions, IndexName, number) => {
    if (fieldNames.length !== conditions.length) {
        throw new Error('Field names and conditions arrays must have the same length');
    }

    let expressionAttributeNames = {}, fleg = false
    let lastEvaluatedKey = null;
    let allItems = [];
    let keysToChange = ['Pathology Label Group', 'Pathology Label Multi Class', 'Pathology Label Detailed']
    do {
        const keyConditionExpression = fieldNames.map((fieldName, index) => {
            if (!keysToChange.includes(fieldName))
                return `${fieldName} = :value${index}`
            else {
                expressionAttributeNames[`#${fieldName.replaceAll(" ", "_")}`] = fieldName
                fleg = true
                return `#${fieldName.replaceAll(" ", "_")} = :value${index}`
            }

        }).join(' AND ');
        const expressionAttributeValues = {};
        conditions.forEach((condition, index) => {
            expressionAttributeValues[`:value${index}`] = { S: condition };
        });
        let params = {
            TableName: `oncoredox-${tableName}-${envi.enviroment}`,
            IndexName: IndexName,
            KeyConditionExpression: keyConditionExpression,
            ExpressionAttributeValues: expressionAttributeValues,
            ExclusiveStartKey: lastEvaluatedKey, // Use the last evaluated key for pagination
        };
        if (fleg) params = { ...params, ExpressionAttributeNames: expressionAttributeNames }
        const data = (await axios.post("http://54.174.123.25:8000/visits/", { f: "query", params: params })).data
        if (data.Items) {
            allItems = allItems.concat(data.Items);
        }

        lastEvaluatedKey = data.LastEvaluatedKey;

    } while (lastEvaluatedKey);

    return allItems;
};


export const getFullItemsUrinalysis = async (tableName) => {
    const originalParams = {
        TableName: `oncoredox-${tableName}-${envi.enviroment}`,
        Select: 'COUNT'
    };

    const filteredParams = {
        TableName: `oncoredox-${tableName}-${envi.enviroment}`,
        FilterExpression: 'attribute_exists(pH) AND pH <> :emptyString',
        ExpressionAttributeValues: {
            ':emptyString': { S: '' }
        },
        Select: 'COUNT'
    };

    try {
        const originalData = (await axios.post("http://54.174.123.25:8000/visits/", { f: "scan", param: originalParams })).data
        const filteredData = (await axios.post("http://54.174.123.25:8000/visits/", { f: "scan", param: filteredParams })).data

        const originalLength = originalData.Count || 0;
        const filteredLength = filteredData.Count || 0;

        return {
            originalLength,
            filteredLength
        };
    } catch (error) {
        console.error("Error getting items", error);
        throw error;
    }
};


export const getFullItemsPathology = async (tableName) => {
    const originalParams = {
        TableName: `oncoredox-${tableName}-${envi.enviroment}`,
        Select: 'COUNT'
    };

    const filteredParams = {
        TableName: `oncoredox-${tableName}-${envi.enviroment}`,
        FilterExpression: 'attribute_exists(#PathologyLabelDetailed) AND #PathologyLabelDetailed <> :emptyString',
        ExpressionAttributeNames: {
            '#PathologyLabelDetailed': 'Pathology Label Detailed',
        },
        ExpressionAttributeValues: {
            ':emptyString': { S: '' }
        },
        Select: 'COUNT'
    };

    try {
        const originalData = (await axios.post("http://54.174.123.25:8000/visits/", { f: "scan", param: originalParams })).data
        const filteredData = (await axios.post("http://54.174.123.25:8000/visits/", { f: "scan", param: filteredParams })).data

        const originalLength = originalData.Count || 0;
        const filteredLength = filteredData.Count || 0;

        return {
            originalLength,
            filteredLength
        };
    } catch (error) {
        console.error("Error getting items", error);
        throw error;
    }
};


export const getSpecificUrineID = async (tableName, fieldNames, conditions, IndexName, number) => {
    let newListID = []

    let list = await getByQuery(tableName, fieldNames, conditions, IndexName, number)
    list.forEach(async (urinary) => {
        newListID.push(urinary.Study_ID.S)
    })
    return newListID
}



export const Put = async (itemId, newValues, tableName) => {
    let visitDate = newValues.Visit_Date.S
    let updateExpressionArray = [], expressionAttributeValues = {}, updateExpression = "set ", expressionAttributeNames = []
    let keysToRemove = ["Visit_Date", "Study_ID"]
    for (let key in keysToRemove) {
        if (keysToRemove[key] in newValues) {
            delete newValues[keysToRemove[key]]; // Remove the key-value pair
        }
    }

    let keysToChange = ['Urine Culture Result', 'Urine Culture Notes', 'Risk Category', 'Tumor Grade', 'Tumor Stage', 'Clinical History and Treatments', 'TURBT Pathology Report', 'Pathology Notes', 'Pathology Label Detailed', 'Pathology Label General', 'Reason for Risk Category', 'Size above 3cm', 'Multiple Tumors']
    Object.keys(newValues).forEach((fieldName, index) => {
        const placeholder = `: value${index}`;
        if ('Evidents of Invasion/Present of Muscle' == fieldName) {
            updateExpressionArray.push(`#Invasion = ${placeholder} `);
            expressionAttributeNames[`#Invasion`] = fieldName
        }
        else if (fieldName == "Operator" || fieldName == "Current") {
            updateExpressionArray.push(`#${fieldName} = ${placeholder} `);
            expressionAttributeNames[`#${fieldName} `] = fieldName
        }
        else if (keysToChange.includes(fieldName)) {
            updateExpressionArray.push(`#${fieldName.replace(/ /g, "_")} = ${placeholder} `);
            expressionAttributeNames[`#${fieldName.replace(/ /g, "_")} `] = fieldName
        }
        else {
            updateExpressionArray.push(`${fieldName} = ${placeholder} `);
        }
        expressionAttributeValues[placeholder] = newValues[fieldName]
    });

    updateExpressionArray.forEach((element) => {
        updateExpression += element + ", "
    })

    updateExpression = updateExpression.slice(0, -2); //remove last ,

    let params = {
        TableName: `oncoredox - ${tableName} -${envi.enviroment} `,
        Key: {
            Study_ID: { S: itemId },
            Visit_Date: { S: visitDate }
        },
        UpdateExpression: updateExpression,
        ExpressionAttributeValues: expressionAttributeValues,
    };

    if (Object.keys(expressionAttributeNames).length !== 0) {
        params = { ...params, ExpressionAttributeNames: expressionAttributeNames }
    }

    try {
        await axios.post("http://54.174.123.25:8000/visits/", { f: "updateItem", params: params })
        console.log("Item updated successfully.");
    } catch (error) {
        console.error("Error inserting data:", error);
    }
}

export const Post = async (params, tableName) => {
    let values = {
        TableName: `oncoredox - ${tableName} -${envi.enviroment} `,
        Item: params
    };
    try {
        axios.post("http://54.174.123.25:8000/visits/", { f: "putItem", values: values })
        console.log('Item created successfully.');
    } catch (error) {
        console.error('Error creating item:', error);
    }
}