import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { ColumnProps } from 'antd/es/table';
import { Avatar, Table, Button, Modal, Tag } from 'antd';

import { IPatient } from '../../../interfaces/patient';

import PatientForm from '../../../layout/components/patients/PatientForm';
import axios from 'axios';
import { Console, log } from 'console';
import EditObservationVisit from '../../../layout/components/patients/EditObservtionVisit';
import EditUrinaryVisit from '../../../layout/components/patients/EditUrinaryVisit';
import EditPathologyVisit from '../../../layout/components/patients/EditPathologyVisit';
import { check } from 'prettier';
import links from '../../../Config/links.json'
import reservedWords from '../../../Config/ReservedWords.json'
import { Auth } from 'aws-amplify';
import '../../../custom-table.css'; // Import your custom CSS file
import { useUserGroups } from '../../../UserGroupsContext';

type Props = {
  patients: IPatient[];
  onEditPatient?: (patient: IPatient) => void;
  onDeletePatient?: (id: string) => void;
};

const PatientsTable = ({

  patients,
  onEditPatient = () => null,
  onDeletePatient = () => null
}: Props) => {
  const history = useHistory();
  const [patient, setPatient] = useState(null);
  const [visit, setVisit] = useState(null);
  const [PatientsTable, setPatientsTable] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [visitVisibility, setVisitVisibility] = useState(false);
  const [flag, setFlag] = useState(false);
  const [observations, setObservations] = useState([])
  const [pathology, setPathology] = useState([])
  const [urinalysis, setUrinalysis] = useState([])
  const [expandedRows, setExpandedRows] = useState({});
  const [lastPatientID, setlastPatientID] = useState()
  const [editType, setType] = useState()
  const [lastVisit, setlastVisit] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [missedData, setMissedData] = useState(0)
  const [noDayZero, setNoDayZero] = useState(false)
  const closeModal = () => setVisibility(false);
  const closeVisitModal = () => setVisitVisibility(false);
  const handleShowInfo = () => history.push('/vertical/patient-profile');
  const handleDeletePatient = (id) => onDeletePatient(id);
  const userGroups = useUserGroups();

  const actions = (patient: IPatient) => (
    <div className='buttons-list nowrap'>
      {/* <Button shape='circle' onClick={handleShowInfo}>
        <span className='icofont icofont-external-link' />
      </Button> */}
      <Button onClick={handleEditPatient.bind({}, patient)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      {/* <Button onClick={handleDeletePatient.bind({}, patient.id)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button> */}
    </div>
  );

  const createVisitActions = (type) => (patient) => {
    return (
      <div className='buttons-list nowrap'>
        <Button onClick={() => { handleVisitPatient(patient, type) }} shape='circle' type='primary'>
          <span className='icofont icofont-edit-alt' />
        </Button>
      </div>

    )
  };

  const nestedColumns = [
    {
      title: 'Visit Date',
      dataIndex: 'Visit_Date',
      key: 'visitDate',
      sorter: (a, b) => (parseInt(a.Visit_Date.S) > parseInt(b.Visit_Date.S) ? 1 : -1),
      render: (text) => { if (text != undefined) return text.S }
    },
    { title: 'Admit Reason', dataIndex: 'Admit_Reason_Follow_Up', key: 'admitReasonFollowUp', render: (text) => { if (text != undefined) return text.S } }, // Extract the string value from {S: ''}},
    { title: 'Collection Method', dataIndex: 'Collection_Method', key: 'collectionMethod', render: (text) => { if (text != undefined) return text.S } },
    { title: 'LOT Number', dataIndex: 'LOT_Number', key: 'LOTNumber', render: (text) => { if (text != undefined) return text.S } }, ,
    { title: 'Run', dataIndex: 'Run_Number', key: 'run', render: (text) => { if (text != undefined) return text.S } },
  ];

  const pathologyColumns = [
    { title: 'Risk', dataIndex: 'Risk Category', key: 'riskCategory', render: (text) => { if (text != undefined) return text.S } },
    { title: 'Tumor Grade', dataIndex: 'Tumor Grade', key: 'tumorGrade', render: (text) => { if (text != undefined) return text.S } },
    { title: 'Tumor Stage', dataIndex: 'Tumor Stage', key: 'tumorStage', render: (text) => { if (text != undefined) return text.S } },
    { title: 'Invasion', dataIndex: 'Evidents of Invasion/Present of Muscle', key: 'eoi', render: (text) => { if (text != undefined) return text.S } },
    { title: 'History', dataIndex: 'Clinical History and Treatments', key: 'clinicalHistoryAndTreatments', render: (text) => { if (text != undefined) return text.S; } }, // Extract the string value from {S: ''}},
    { title: 'Report', dataIndex: 'TURBT Pathology Report', key: 'tpr', render: (text) => { if (text != undefined) return text.S } },
    { title: 'Notes', dataIndex: 'Pathology Notes', key: 'pathologyNotes', render: (text) => { if (text != undefined) return text.S } }
  ];

  const urinalysisColumns = [
    { title: 'pH', dataIndex: 'pH', key: 'pH', render: (text) => { if (text != undefined) return text.S } },
    { title: 'Glucose', dataIndex: 'Glucose', key: 'glucose', render: (text) => { if (text != undefined) return text.S } },
    { title: 'Protein', dataIndex: 'Protein', key: 'protein', render: (text) => { if (text != undefined) return text.S } },
    { title: 'RBC', dataIndex: 'RBC', key: 'RBC', render: (text) => { if (text != undefined) return text.S } },
  ];

  const columns: ColumnProps<IPatient>[] = [
    {
      key: 'id',
      dataIndex: 'Study_ID',
      title: 'Study ID',
      sorter: (a, b) => (parseInt(a.Study_ID.S) > parseInt(b.Study_ID.S) ? 1 : -1),
      render: (Study_ID, record, rowIndex) => {
        let color = 'black'  //rowIndex == 0 ? 'black' : '#a5a5a5'
        return (
          <span className='nowrap' style={{ color: color }}>
            {Study_ID.S}
          </span>
        );
      }
    },
    {
      key: 'gender',
      dataIndex: 'Gender',
      title: 'Gender',
      render: (Gender, record, rowIndex) => {
        let color = 'black'
        return (
          <span className='nowrap' style={{ color: color }}>
            {Gender.S}
          </span>
        )
      }
    },
    {
      key: 'birthYear',
      dataIndex: 'Birth_Year',
      title: 'Birth Year',
      render: (Birth_Year, record, rowIndex) => {
        let color = 'black'
        return (<span className='d-flex align-baseline nowrap' style={{ color: color }}>{Birth_Year.S}</span>)
      }
    },
    {
      key: 'studyPopulation',
      dataIndex: 'Study_Population',
      title: 'Study Population',
      render: (Study_Population, record, rowIndex) => {
        let color = 'black'
        return (
          <span className='d-flex align-baseline nowrap' style={{ color: color }}>
            {/* <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} /> */}
            {Study_Population.S}
          </span>
        )
      }
    },
    {
      key: 'medicalSite',
      dataIndex: 'Medical_Site',
      title: 'Medical Site',
      render: (Medical_Site, record, rowIndex) => {
        let color = 'black'
        return (
          <span className='d-flex align-baseline nowrap' style={{ color: color }}>
            {/* <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} /> */}
            {Medical_Site.S}
          </span>
        )
      },
    }
  ];



  if (!disabled) {
    pathologyColumns.push({
      key: 'actions',
      title: 'Actions',
      render: createVisitActions(reservedWords.pathologyStr)
    } as any);
    urinalysisColumns.push({
      key: 'actions',
      title: 'Actions',
      render: createVisitActions(reservedWords.urinaryStr)
    } as any)
    columns.push({
      key: 'actions',
      title: 'Actions',
      render: actions
    })
  }

  const handleEditPatient = (patient: IPatient) => {
    setPatient(patient);
    setVisibility(true);
  };

  const handleVisitPatient = (patient, type) => {
    setVisit(patient);
    setVisitVisibility(true);
    setType(type)
  };

  const handleRowExpand = async (expanded, record) => {
    if (expanded) {
      getVisitsForPatient(record)

      // Update the state to mark the row as expanded
      setExpandedRows(prevExpandedRows => ({
        ...prevExpandedRows,
        [record.Study_ID.S]: true
      }));
    } else {
      // Update the state to mark the row as collapsed
      setExpandedRows(prevExpandedRows => ({
        ...prevExpandedRows,
        [record.Study_ID.S]: false
      }));
    }
    setlastPatientID(record.Study_ID.S)
    setPatient(record)
  };


  const handleNestedRowExpand = async (expanded, record) => {
    if (expanded) {
      // Update the state to mark the row as expanded
      setExpandedRows(prevExpandedRows => ({
        ...prevExpandedRows,
        [record.Study_ID.S]: true
      }));
    } else {
      // Update the state to mark the row as collapsed
      setExpandedRows(prevExpandedRows => ({
        ...prevExpandedRows,
        [record.Study_ID.S]: false
      }));
    }
    setlastVisit(record)
  };


  const getVisitsByID = async (id, tableName) => {
    let tableData = (await axios.post("http://54.174.123.25:8000/visits/", { f: "GetById", id: patient.Study_ID.S, tableName: "pathology" })).data
    return tableData
  }

  function sortByVisitDate(a, b) {
    const dateAString = a.Visit_Date.S;
    const dateBString = b.Visit_Date.S;

    // Split the date strings by spaces to separate date and time
    const dateAArray = dateAString.split(" ");
    const dateBArray = dateBString.split(" ");

    // Extract the date and time components
    const dateAComponents = dateAArray.slice(0, 2).join(" ");
    const timeA = dateAArray[2];
    const dateBComponents = dateBArray.slice(0, 2).join(" ");
    const timeB = dateBArray[2];

    // Compare date components
    if (dateAComponents < dateBComponents) return 1;
    if (dateAComponents > dateBComponents) return -1;

    // If date components are equal, compare the time components
    if (timeA < timeB) return -1;
    if (timeA > timeB) return 1;

    // If both date and time components are equal, consider the dates equal
    return 0;
  }

  //separte the relevnt visits of the specific patient
  const getVisitsForPatient = async (patient) => {
    let observationsData = (await axios.post("http://54.174.123.25:8000/visits/", { f: "GetById", id: patient.Study_ID.S, tableName: "observations" })).data
    let noDayZero = true;
    observationsData.forEach((observation) => {
      if (observation.Admit_Reason_Follow_Up && observation.Admit_Reason_Follow_Up.S == reservedWords.dayZero) {
        noDayZero = false;
      }
    })

    observationsData.sort(sortByVisitDate)
    setNoDayZero(noDayZero)
    setObservations(observationsData)
    let pathologyData = (await axios.post("http://54.174.123.25:8000/visits/", { f: "GetById", id: patient.Study_ID.S, tableName: "pathology" })).data
    setPathology(pathologyData)
    let urinalyData = (await axios.post("http://54.174.123.25:8000/visits/", { f: "GetById", id: patient.Study_ID.S, tableName: "urinalysis" })).data
    setUrinalysis(urinalyData)
  };

  const refreshData = () => {
    setFlag(!flag); // Toggle the flag to trigger a refresh
  };


  const getPatientsByRoll = async (patients) => {
    //if the permission is CareGiver-Belinson-BladderCancer-Edit show table without healthy patient
    let permisson = (await axios.post("http://54.174.123.25:8000/patients/", { f: "CheckPermission", userGroups: userGroups, permissions: ["CareGiver-Belinson-BladderCancer-Edit", "CareGiver-Belinson-BladderCancer-Review"] })).data
    let newPatients = patients
    if (permisson) {
      newPatients = patients.filter((patient) => patient.Study_Population && patient.Study_Population.S == "Bladder Cancer" && patient.Medical_Site && patient.Medical_Site.S == "Belinson")
    }
    return newPatients
  }

  useEffect(() => {
    let start = async () => {
      try {
        //get all the patients
        let patients = (await axios.get("http://54.174.123.25:8000/patients/")).data
        patients = await getPatientsByRoll(patients)
        patients.sort((a, b) => (parseInt(a.Study_ID.S) > parseInt(b.Study_ID.S) ? -1 : 1))
        setPatientsTable(patients)
        let permission = (await axios.post("http://54.174.123.25:8000/patients/", { f: "CheckPermission", userGroups: userGroups, permissions: ["CareGiver-Belinson-BladderCancer-Edit", "Admin"] })).data
        if (permission)
          setDisabled(false)
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    };
    start()
  }, [flag, lastVisit, visit])

  const pagination = patients.length <= 10 ? false : {};

  let title = "Observtion"
  let editVisitComp = (
    <EditObservationVisit
      submitText='Update'
      onCancel={closeVisitModal}
      onSubmit={onEditPatient}
      patient={visit}
      onDataUpdated={async () => {
        let observations = await getVisitsByID(patient.Study_ID.S, "observations");
        setObservations(observations)
        refreshData()
      }}
    />
  )

  if (editType == reservedWords.urinaryStr) {
    editVisitComp = (
      <EditUrinaryVisit
        submitText='Update'
        onCancel={closeVisitModal}
        onSubmit={onEditPatient}
        patient={visit}
        onDataUpdated={async () => {
          let urinalysis = await getVisitsByID(patient.Study_ID.S, "urinalysis");
          setUrinalysis(urinalysis)
          refreshData()
        }}
      />
    )
    title = "Urinalysis"
  }

  else if (editType == reservedWords.pathologyStr) {
    editVisitComp = (
      <EditPathologyVisit
        submitText='Update'
        onCancel={closeVisitModal}
        onSubmit={onEditPatient}
        patient={visit}
        onDataUpdated={async () => {
          let pathology = await getVisitsByID(patient.Study_ID.S, "pathology");
          setPathology(pathology)
          refreshData()
        }}
      />
    )
    title = "Pathology"
  }
  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={PatientsTable}
        columns={columns}
        rowClassName={"gray-row"} //(record, index) => index % 2 === 0 ? 'gray-row' : 'gray-row'
        expandable={{
          expandedRowRender: (record) => {

            if (record.Study_ID.S == lastPatientID) { //open just the relevent row
              return (
                <>
                  <Table
                    columns={nestedColumns}
                    dataSource={observations}
                    pagination={false}
                    rowClassName={"gray-row"}
                    expandable={{
                      expandedRowRender: (record) => {
                        let pathologyData = pathology.filter(visit => visit.Visit_Date.S == record.Visit_Date.S)
                        if (pathologyData.length == 0) {
                          let pathologyDataJson = {}
                          pathologyColumns.forEach((data) => {
                            if (data.title != "Actions")
                              pathologyDataJson[data.dataIndex] = { S: "" };
                          })
                          pathologyDataJson["Visit_Date"] = record.Visit_Date
                          pathologyDataJson["Study_ID"] = record.Study_ID
                          pathologyData = [pathologyDataJson]
                        }

                        let urinaryData = urinalysis.filter(visit => visit.Visit_Date.S == record.Visit_Date.S)
                        let urinaryTable = urinaryData.length != 0 ? <Table columns={urinalysisColumns} dataSource={urinaryData} pagination={false} /> : ""
                        //if the admit reason is Day 0 show pathology report even if it not really in DB (it add to db when editPathology done)
                        if (JSON.stringify(record) == JSON.stringify(lastVisit)) { //open just the relevent row
                          let pathologyTable = null;
                          if (record.Admit_Reason_Follow_Up && record.Admit_Reason_Follow_Up.S == reservedWords.dayZero) {
                            pathologyTable = <Table
                              columns={pathologyColumns}
                              dataSource={pathologyData}
                              pagination={false}
                            />
                          }
                          else {
                            if (noDayZero && record.Admit_Reason_Follow_Up && record.Admit_Reason_Follow_Up.S == "Day before TURBT" && record.Run_Number && record.Run_Number.S == "1") {
                              pathologyTable = <Table
                                columns={pathologyColumns}
                                dataSource={pathologyData}
                                pagination={false}
                              />
                            }
                          }
                          return (
                            <>
                              {expandedRows[record.Study_ID.S] && urinaryTable}

                              {expandedRows[record.Study_ID.S] && pathologyTable}
                            </>
                          )
                        }
                      },
                      onExpand: handleNestedRowExpand
                    }}
                  />
                </>
              );
            }
          },
          onExpand: handleRowExpand
        }}
      />

      {patient && (
        <Modal
          open={visibility}
          footer={null}
          onCancel={closeModal}
          title={<h3 className='title'>Edit Patient</h3>}
        >
          <PatientForm
            submitText='Update patient'
            onCancel={closeModal}
            onSubmit={onEditPatient}
            patient={patient}
            onDataUpdated={refreshData}
            isEdit={true}
          />
        </Modal>
      )}

      {visit && (
        <Modal
          open={visitVisibility}
          footer={null}
          onCancel={closeVisitModal}
          title={<h3 className='title'>{"Edit " + title}</h3>}
        >
          {editVisitComp}
        </Modal>
      )}
    </>
  );
}
export default PatientsTable;
