import React, { useState, useEffect } from 'react';

import { Button, Select, Input } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ImageLoader from './ImageLoader';
import { hasErrorFactory } from '../../../utils/hasError';

import { IPatient } from '../../../interfaces/patient';
import axios from 'axios';
import { Console, log } from 'console';
import pathologyOpt from '../../../Config/pathologyOpt.json'
import links from '../../../Config/links.json'
import reservedWords from '../../../Config/ReservedWords.json'
import { Put, Post } from '../../../crud/visits';

const { TextArea } = Input;
type Props = {
  onSubmit: (patient: IPatient) => void;
  onCancel: () => void;
  patient?: IPatient;
  submitText?: string;
  onDataUpdated?: (record) => void;
  isEdit?: boolean;
};

const defaultSubmitText = 'Add patient';
const emptyPatient = {
  name: null,
  address: null,
  status: null,
  age: null,
  number: null,
  gender: null,
  img: null
};

const patientScheme = Yup.object({
  name: Yup.string().required(),
  address: Yup.string().required(),
  status: Yup.string().required(),
  age: Yup.string().required(),
  number: Yup.string().required(),
  gender: Yup.string().required(),
  img: Yup.string().required()
});

const EditPathologyVisit = ({
  submitText = defaultSubmitText,
  patient = emptyPatient,
  onSubmit,
  onCancel,
  onDataUpdated,
  isEdit,
}: Props) => {
  const {
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleSubmit,
    setValues,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid,

  } = useFormik<IPatient>({
    validationSchema: patientScheme,
    initialValues: patient,
    onSubmit: (values) => {
      onSubmit(values);
      onCancel()
    }
  });

  const handleGenderSelect = (value) => setFieldValue('gender', value);
  const handleStatusSelect = (value) => setFieldValue('status', value);
  const [visit, setVisit] = useState(patient)
  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleImageLoad = (img) => {
    setValues({ ...values, img });
  };


  const createPathology = async (visit) => {
    try {
      await Post(visit, "pathology")
    }
    catch (error) {
      console.log(error)
    }
  }

  const editPathology = async (visit) => {
    try {
      console.log(visit)
      await Put(patient.Study_ID.S, { ...visit, Study_ID: patient.Study_ID, Visit_Date: patient.Visit_Date }, "pathology")
    }
    catch (e) {
      console.log(e)
    }
  }

  let updateVisit = async () => {
    try {
      editPathology(visit)
      onDataUpdated(visit)
      onCancel()
    }
    catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    console.log(patient)
    setVisit(patient)
  }, [patient])
  return (
    <>

      <form onSubmit={handleSubmit}>
        {/* <div className='form-group'>
          <ImageLoader onLoad={handleImageLoad} src={values.img as string} />
        </div> */}

        <div className='form-group'>
          <Input
            placeholder={`Study ID: ${patient.Study_ID.S}`}
            name='studyID'
            type='text'
            onBlur={handleBlur}
            disabled={true}
          />
        </div>


        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                onBlur={handleBlur}
                onChange={(value) => { setVisit({ ...visit, 'Risk Category': { S: value } }) }}
                value={visit['Risk Category'] ? 'Risk Category: ' + visit['Risk Category'].S : 'Risk Category: '}
              >
                {pathologyOpt.risk.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
              </Select>
            </div>

          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input.TextArea
                placeholder={'Reason for Risk Category: '}
                value={visit['Reason for Risk Category'] ? visit['Reason for Risk Category'].S : ''}
                name='Reason for Risk Category'
                onBlur={handleBlur}
                onChange={(e) => { setVisit({ ...visit, 'Reason for Risk Category': { S: e.target.value } }) }}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                value={visit['Tumor Grade'] && visit['Tumor Grade'].S != "" ? visit['Tumor Grade'].S : 'Tumor Grade: '}
                onBlur={handleBlur}
                onChange={(value) => { setVisit({ ...visit, 'Tumor Grade': { S: value } }) }}
              >
                {pathologyOpt.tumorGrade.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
              </Select>
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                value={visit['Tumor Stage'] && visit['Tumor Stage'].S != "" ? visit['Tumor Stage'].S : 'Tumor Stage: '}
                onBlur={handleBlur}
                onChange={(value) => { setVisit({ ...visit, 'Tumor Stage': { S: value } }) }}
              >
                {pathologyOpt.tumorStage.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
              </Select>
            </div>
          </div>
        </div>


        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                value={visit['Multiple Tumors'] && visit['Multiple Tumors'].S != "" ? 'Multiple Tumors: ' + visit['Multiple Tumors'].S : 'Multiple Tumors: '}
                onBlur={handleBlur}
                onChange={(value) => { setVisit({ ...visit, 'Multiple Tumors': { S: value } }) }}
              >
                {pathologyOpt.boolean.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
              </Select>
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                value={visit['Size above 3cm'] && visit['Size above 3cm'].S != "" ? visit['Size above 3cm'].S : 'Size above 3cm: '}
                onBlur={handleBlur}
                onChange={(value) => { setVisit({ ...visit, 'Size above 3cm': { S: value } }) }}
              >
                {pathologyOpt.boolean.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
              </Select>
            </div>
          </div>
        </div>


        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                value={visit['Pathology Label Detailed'] && visit['Pathology Label Detailed'].S != "" ? visit['Pathology Label Detailed'].S : 'Pathology Label Detailed: '}
                onBlur={handleBlur}
                onChange={(value) => { setVisit({ ...visit, 'Pathology Label Detailed': { S: value } }) }}
              >
                {pathologyOpt.pathologyDetailed.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
              </Select>
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                value={visit['Pathology Label General'] && visit['Pathology Label General'].S != "" ? visit['Pathology Label General'].S : 'Pathology Label General: '}
                onBlur={handleBlur}
                onChange={(value) => { setVisit({ ...visit, 'Pathology Label General': { S: value } }) }}
              >
                {pathologyOpt.pathologyGeneral.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
              </Select>
            </div>
          </div>
        </div>


        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Input.TextArea
                placeholder={'Invasion: '}
                value={visit['Evidents of Invasion/Present of Muscle'] ? visit['Evidents of Invasion/Present of Muscle'].S : ''}
                name='Evidents of Invasion/Present of Muscle'
                onBlur={handleBlur}
                onChange={(e) => { setVisit({ ...visit, 'Evidents of Invasion/Present of Muscle': { S: e.target.value } }) }}
              />
            </div>
          </div>

          <div className='form-group'>
            <Input.TextArea
              placeholder='History:'
              value={visit['Clinical History and Treatments'] ? visit['Clinical History and Treatments'].S : ''}
              name='Clinical History and Treatments'
              onBlur={handleBlur}
              onChange={(e) => { setVisit({ ...visit, 'Clinical History and Treatments': { S: e.target.value } }) }}
            />
          </div>

        </div>


        <div className='form-group'>
          <Input.TextArea
            placeholder='Report: '
            value={visit['TURBT Pathology Report'] ? visit['TURBT Pathology Report'].S : ''}
            name='TURBT Pathology Report'
            onBlur={handleBlur}
            onChange={(e) => { setVisit({ ...visit, 'TURBT Pathology Report': { S: e.target.value } }) }}
          />
        </div>

        <div className='form-group'>
          <Input.TextArea
            placeholder='Notes'
            value={visit['Pathology Notes'] ? visit['Pathology Notes'].S : ''}
            name='Pathology Notes'
            onBlur={handleBlur}
            onChange={(e) => { setVisit({ ...visit, 'Pathology Notes': { S: e.target.value } }) }}
          // defaultValue={ID}
          // className={hasError('name')}
          />
        </div>




        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button type='primary' htmlType='submit' onClick={() => updateVisit()}>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditPathologyVisit;