
import React, { useState, useEffect } from 'react';

import { Button, Select, Input } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../../utils/hasError';

import { IPatient } from '../../../interfaces/patient';
import axios from 'axios';
import { Console } from 'console';
import sensorOpt from '../../../Config/SensorOpt.json'

const { TextArea } = Input;
type Props = {
    onSubmit: (patient: IPatient) => void;
    onCancel: () => void;
    patient?: IPatient;
    submitText?: string;
    onDataUpdated?: () => void;
    isEdit?: boolean;
};

const defaultSubmitText = 'Add patient';
const emptyPatient = {
    name: null,
    address: null,
    status: null,
    age: null,
    number: null,
    gender: null,
    img: null
};

const patientScheme = Yup.object({
    name: Yup.string().required(),
    address: Yup.string().required(),
    status: Yup.string().required(),
    age: Yup.string().required(),
    number: Yup.string().required(),
    gender: Yup.string().required(),
    img: Yup.string().required()
});

const SensorForm = ({
    submitText = defaultSubmitText,
    patient = emptyPatient,
    onSubmit,
    onCancel,
    onDataUpdated,
    isEdit,
}: Props) => {
    const {
        setFieldTouched,
        setFieldValue,
        handleChange,
        handleSubmit,
        setValues,
        handleBlur,
        resetForm,
        touched,
        values,
        errors,
        isValid,

    } = useFormik<IPatient>({
        validationSchema: patientScheme,
        initialValues: patient,
        onSubmit: (values) => {
            onSubmit(values);
            onCancel()
        }
    });

    const handleGenderSelect = (value) => setFieldValue('gender', value);
    const handleStatusSelect = (value) => setFieldValue('status', value);
    const [ID, setID] = useState("")
    const [Birth_Year, setYear] = useState("")
    const [QA, setQA] = useState("")
    const [notes, setNotes] = useState("")
    const [Study_Population, setStudyPopulation] = useState("")
    const [Medical_Site, setMedicalSite] = useState("")
    const hasError = hasErrorFactory(touched, errors);
    const [disabled, setDisabled] = useState(false)
    const [add, isAdd] = useState(false)

    const handleCancel = () => {
        resetForm();
        onCancel();
    };

    const handleImageLoad = (img) => {
        setValues({ ...values, img });
    };

    useEffect(() => {
        if (patient.QA)
            setQA(patient.QA.S)
        if (patient.Notes)
            setNotes(patient.Notes.S)
    }, [])


    let updateSensor = async () => {
        try {
            console.log(patient.Sensor_ID.S)
            await axios.post("http://54.174.123.25:8000/sensors", { f: "put", "id": patient.Sensor_ID.S, "QA": QA, "notes": notes })
            onDataUpdated()
            onCancel()
        }
        catch(e) {
            console.log(e)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='form-group'>
                        <Input
                            placeholder={`Sensor ID: ${patient.Sensor_ID.S}`}
                            name='studyID'
                            type='text'
                            onBlur={handleBlur}
                            disabled={true}
                            onChange={(e) => { setID(e.target.value) }} //need to change?
                        // defaultValue={ID}
                        // className={hasError('name')}
                        />
                    </div>


                    <div className='col-sm-6 col-12'>
                        <div className='form-group'>
                            <Select
                                placeholder='QA'
                                // defaultValue={Gender}
                                onChange={(value) => setQA(value)}
                                onBlur={handleBlur}
                            >
                                {sensorOpt.Sensor.map((opt) => <Select.Option value={opt} >{opt}</Select.Option>)}
                            </Select>
                        </div>
                    </div>
                </div>

                <div className='form-group'>
                    <Input.TextArea
                        placeholder='Notes'
                        name='Notes'
                        onBlur={handleBlur}
                        onChange={(e) => { setNotes(e.target.value) }}
                    // defaultValue={ID}
                    // className={hasError('name')}
                    />
                </div>



                <div className='d-flex justify-content-between buttons-list settings-actions'>
                    <Button danger onClick={handleCancel}>
                        Cancel
                    </Button>

                    <Button type='primary' htmlType='submit' onClick={() => updateSensor()}>
                        {submitText}
                    </Button>
                </div>
            </form>
        </>
    );
};

export default SensorForm;

